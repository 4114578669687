<template>
  <div class="main">
    <el-row :gutter="20">
      <el-form
        ref="form"
        :model="form"
        label-position="top"
        :rules="rules"
        @validate="validateForm"
      >
        <el-col :span="formColumnSpan">
          <el-form-item label="問卷標題" prop="title">
            <el-input
              v-model="form.title"
              placeholder="請輸入問卷標題，字數限制30字以內"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="formColumnSpan">
          <el-form-item prop="edition_type">
            <span slot="label">
              問卷版型
              <el-popover
                placement="right"
                :visible-arrow="false"
                trigger="hover"
                popper-class="popoverBg"
              >
                <img
                  slot="reference"
                  src="../../assets/image/icon-info.png"
                  width="22"
                  style="transform: translateY(5px)"
                />
                <div>* 每頁多題組：一頁呈現5個題組。</div>
              </el-popover>
            </span>
            <el-select v-model="form.edition_type">
              <el-option :value="0" label="每頁一題組"></el-option>
              <el-option :value="1" label="每頁多題組"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="formColumnSpan">
          <el-form-item label="問卷進入頁上方圖片" prop="type">
            <upload-image :image.sync="form.image"></upload-image>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="問卷介紹" prop="content">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="請輸入問卷介紹，字數限制1000字以內"
              v-model="form.content"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="formColumnSpan">
          <el-form-item label="感謝作答圖片" prop="img">
            <upload-image :image.sync="form.thank_image"></upload-image>
          </el-form-item>
        </el-col>
        <el-col :span="formColumnSpan">
          <el-form-item label="感謝作答語" prop="thank_word">
            <el-input
              type="textarea"
              v-model="form.thank_word"
              placeholder="請輸入感謝作答語，字數限制1000字以內"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
// import { VueEditor } from 'vue2-editor';
import UploadImage from '@/components/common/UploadImage';

export default {
  name: 'questionnaire-form',
  components: {
    UploadImage,
  },
  props: ['step1', 'hasError'],
  data() {
    return {
      rules: {
        title: [
          { valid: true },
          { required: true, message: '請輸入問卷標題', trigger: 'blur' },
          { max: 30, message: '字數不可超過30字', trigger: 'blur' },
        ],
        content: [
          { valid: true },
          { max: 1000, message: '字數不可超過1000字', trigger: 'blur' },
        ],
        thank_word: [
          { valid: true },
          { max: 1000, message: '字數不可超過1000字', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['formColumnSpan']),
    form: {
      get() {
        return this.step1;
      },
      set(val) {
        this.$emit('update:step1', val);
      },
    },
  },
  methods: {
    validateForm(formName, valid) {
      this.rules[formName][0].valid = valid;
      let error = false;
      Object.keys(this.rules).forEach((el) => {
        if (!this.rules[el][0].valid) {
          error = true;
        }
      });
      this.$emit('update:hasError', error);
    },
  },
};
</script>
<style lang="scss" scoped></style>
