<template>
  <div style="padding-left: 12px">
    <el-upload
      class="upload-demo"
      ref="upload"
      action=""
      :auto-upload="false"
      :show-file-list="false"
      :on-change="changeUpload"
      accept="image/png, image/jpeg"
      :limit="1"
      :file-list="fileList"
    >
      <el-button v-if="!imageLink" slot="trigger" size="small" type="primary">
        選擇檔案
      </el-button>
      <el-button v-else size="small" type="danger" @click.stop="remove">
        移除圖片
      </el-button>
      <span slot="tip" style="margin-left: 20px">(建議尺寸:720x360px, 1M)</span>
    </el-upload>
    <div class="preview">
      <div v-if="!imageLink">圖片預覽</div>
      <img v-if="imageLink" :src="imageLink" alt="" />
    </div>

    <el-dialog
      v-loading="loading"
      title="圖片剪輯"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      custom-class="cropper-dialog"
      append-to-body
      width="760px"
      style="font-family: PingFangTC-Regular"
    >
      <p>*請按住圓點移動調整裁切框大小</p>
      <div class="cropper-content">
        <div class="cropper" style="text-align: center; height: 360px">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :output-size="option.outputSize"
            :output-type="option.outputType"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :fixed="option.fixed"
            :fixed-number="option.fixedNumber"
            :fixedBox="option.fixedBox"
            :can-move-box="option.canMoveBox"
            :center-box="option.centerBox"
            :original="option.original"
            :canScale="option.canScale"
          ></vueCropper>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            remove();
          "
        >
          取消
        </el-button>
        <el-button type="primary" @click="finish" :loading="loading">
          確認
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { doUploadImage } from '@/api/questionnaire';
import { VueCropper } from 'vue-cropper';
export default {
  name: 'UploadImage',
  props: {
    image: { type: String },
  },
  components: {
    VueCropper,
  },
  data() {
    return {
      fileList: [],
      fileInfo: {},
      dialogVisible: false,
      option: {
        img: '', // 裁剪图片的地址
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 720, // 默认生成截图框宽度
        autoCropHeight: 360, // 默认生成截图框高度
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [2, 1], // 截图框的宽高比例
        fixedBox: false,
        canMoveBox: true, // 截图框能否拖动
        centerBox: true, // 截图框是否被限制在图片里面
        original: true,
        canScale: false,
      },
      // 防止重复提交
      loading: false,
    };
  },
  computed: {
    imageLink: {
      get() {
        return this.image;
      },
      set(val) {
        this.$emit('update:image', val);
      },
    },
  },
  methods: {
    changeUpload(file, file_list) {
      // console.log(file);
      // console.log(file_list)
      this.fileList = [];
      const isLt1M = file.size < 1000000;
      if (!isLt1M) {
        this.$message.error('圖片大小不得超過1M');
        return false;
      }
      this.fileList = file_list;
      this.fileInfo = file;
      const temp = URL.createObjectURL(new Blob([file.raw]));
      // console.log(temp);
      this.$nextTick(() => {
        this.option.img = temp;
        this.dialogVisible = true;
      });
    },
    remove() {
      this.fileList = [];
      this.imageLink = '';
      this.fileInfo = {};
    },
    finish() {
      this.$refs.cropper.getCropData((data) => {
        let arr = data.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let myFile = new File([u8arr], this.fileInfo.name, { type: mime });
        // console.log(myFile);
        this.loading = true;
        this.doUpload(myFile);
      });
    },
    doUpload(file) {
      // console.log(file);
      let data = new FormData();
      data.append('file', file);
      data.append('kind', 'questionnaire');
      doUploadImage(data)
        .then((res) => {
          // console.log(res.url);
          this.imageLink = res.url;
          this.loading = false;
          this.dialogVisible = false;
        })
        .catch((error) => {
          this.$alert(error.message, 'ERROR!', {
            type: 'error',
            confirmButtonText: '確定',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  margin-top: 10px;
  min-height: 180px;
  max-width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed lightgray;
  border-radius: 10px;
  padding: 5px;
  font-size: 24px;
  color: lightgray;

  img {
    width: 100%;
  }
}
/deep/.cropper-dialog > .el-dialog__body {
  padding-top: 0;
}
</style>
