<template>
  <div id="div" class="divInput">
    <div class="input" @click="openValue">
      <input
        ref="select"
        v-model="value"
        type="text"
        placeholder="請點擊選擇或輸入關鍵字"
        @keyup.enter="handleKeyword"
      />
      <i
        :class="show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
        style="color: #c0c4cc"
      ></i>
    </div>

    <div class="dropdown-wrap">
      <div class="dropdown" v-show="show">
        <ul class="dropdown_list" v-if="empty === false">
          <section v-if="keywordList.length === 0">
            <li v-for="(el, index) in itemList" :key="index">
              <div class="list_item">
                <span
                  @click="clickItem(2, el)"
                  :style="{
                    fontWeight:
                      el.id === selectedItem.first_level ? 'bolder' : '',
                  }"
                >
                  {{ el.tag_name }}
                </span>
                <i
                  class="el-icon-arrow-right"
                  v-show="el.id === selectedItem.first_level"
                ></i>
              </div>
            </li>
          </section>
          <section v-else>
            <li v-for="(el, index) in keywordList" :key="index">
              <div class="list_item">
                <span @click="clickItem(0, el)">
                  {{ el.tag_name }}
                </span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </li>
          </section>
        </ul>
        <div v-else style="text-align: center">無資料</div>
      </div>
      <ul class="dropdown_list_second" v-show="show && secondList.length !== 0">
        <li v-for="(i, index) in secondList" :key="index">
          <div class="list_item">
            <span
              @click="clickItem(3, i)"
              :style="{
                fontWeight: i.id === selectedItem.second_level ? 'bolder' : '',
              }"
            >
              {{ i.tag_name }}
            </span>
            <i
              class="el-icon-arrow-right"
              v-show="i.id === selectedItem.second_level"
            ></i>
          </div>
        </li>
      </ul>
      <ul class="dropdown_list_third" v-show="show && thirdList.length !== 0">
        <li v-for="(i, index) in thirdList" :key="index">
          <div class="list_item">
            <span @click="clickItem(0, i)">
              {{ i.tag_name }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {
  getItemList,
  getItemKeyword,
  getItemByRuleId,
} from '../api/filterSetting';

export default {
  name: 'nestSelect',
  props: {
    value: {},
    element_select: Boolean,
  },
  data() {
    return {
      show: false,
      loading: false,
      empty: false,
      itemList: [],
      secondList: [],
      thirdList: [],
      keywordList: [],
      selectedItem: {
        first_level: Number,
        second_level: Number,
        third_level: Number,
      },
    };
  },
  mounted() {
    this.getItem(1, 0);
    /* Handle Click OutSide */
    document.addEventListener('click', (e) => {
      let div = document.getElementById('div');
      if (!div?.contains(e.target)) {
        this.show = false;
      }
    });
  },
  watch: {
    itemList() {
      this.secondList = [];
    },
    secondList() {
      this.thirdList = [];
    },
    value(val) {
      if (val === '' && this.itemList.length === 0) {
        this.handleKeyword();
      }
    },
    element_select() {
      if (this.element_select) this.show = false;
    },
  },
  methods: {
    getItem(level, id) {
      getItemList(level, id).then((res) => {
        switch (level) {
          case 1:
            this.itemList = res.data;
            if (this.itemList.length === 0) {
              this.empty = true;
            } else {
              this.empty = false;
            }
            break;
          case 2:
            this.secondList = res.data;
            break;
          case 3:
            this.thirdList = res.data;
            break;
        }
      });
    },
    openValue() {
      this.$emit('update:element_select', false);
      this.show = !this.show;
    },
    clickItem(level, item) {
      if (level === 0) {
        getItemByRuleId(item.rule_id).then((res) => {
          this.show = false;
          this.$emit('getVal', item, res.data[0].path);
        });
      } else {
        this.getItem(level, item.id);
        switch (level - 1) {
          case 1:
            this.selectedItem.first_level = item.id;
            break;
          case 2:
            this.selectedItem.second_level = item.id;
            break;
        }
      }
    },
    handleKeyword() {
      this.loading = true;
      if (this.loading) {
        if (this.value === '') {
          this.getItem(1, 0);
          this.keywordList = [];
        } else {
          getItemKeyword(this.value).then((res) => {
            this.loading = false;
            this.keywordList = res.data;
            this.itemList = [];
            this.secondList = [];
            this.thirdList = [];
            if (this.keywordList.length === 0) {
              this.empty = true;
            } else {
              this.empty = false;
            }
          });
        }
      }
    },
  },
};
</script>
<style type="text/css" lang="scss" scoped>
.divInput {
  position: relative;
  width: 100%;
}
ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}
.input {
  padding: 0 10px 0 15px;
  box-sizing: border-box;
  height: 30px;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}
.input input {
  border: none;
  outline: none;
  width: 90%;
}
.input img {
  position: absolute;
  right: 34px;
  top: 48%;
}

@keyframes dropDown {
  0% {
    height: 0;
    transform: translateY(100);
  }

  100% {
    height: 240px;
    transform: translateY(0);
  }
}

.dropdown-wrap {
  position: absolute;
  top: 45px;
  display: flex;
  z-index: 999;
  width: 100%;
}

.dropdown {
  background: white;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-weight: normal;
  list-style: none;
  transition: all 0.5s ease-in;
  z-index: 5;
  overflow-y: auto;
  width: fit-content;
  min-width: 175px;
  margin-right: 5px;
}

.dropdown:before {
  bottom: 100%;
  content: '';
  height: 10px;
  position: absolute;
  left: 13px;
  width: 15px;
  background-color: #fff;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.dropdown_list {
  box-sizing: border-box;
  max-height: 240px;
  animation: dropDown 0.3s both;
  user-select: none;

  &_second {
    overflow-y: auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background: white;
    height: 240px;
    width: 40%;
    margin-right: 5px;
  }

  &_third {
    overflow-y: auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background: white;
    height: 240px;
    width: 40%;
  }
}

.dropdown_list::-webkit-scrollbar {
  width: 4px;
}

.dropdown_list::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.dropdown_list::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.3);
  border-radius: 20px;
}

.dropdown_list li.selected span {
  background: #f3f8f8;
}

.list_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span {
    width: 100%;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 1);
    color: #646464;
    padding: 0 10px 0 20px;
    text-decoration: none;
    transition: all 0.3s ease-out;
  }

  i {
    padding-right: 12px;
  }

  /* HOVER STATE */
  &:hover {
    background-color: #f5f7fa;
  }
}

/*.dropdown_list li:first-child span {
  border: 0;
}

.dropdown_list li i {
  color: inherit;
  float: right;
}

.dropdown_list li:first-of-type a {
  border-radius: 7px 7px 0 0;
}

.dropdown_list li:last-of-type a {
  border: none;
  border-radius: 0 0 7px 7px;
}*/
</style>
