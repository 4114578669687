<template>
  <div class="main" v-show="isGetData">
    <slot id="backToTop"></slot>
    <v-steps :steps-list="stepsList" :active-steps="activeSteps">
      <template slot="desc1">
        <questionnaire-form
          @activeSteps="getActiveSteps"
          :step1.sync="step1"
          :hasError.sync="hasError"
        ></questionnaire-form>
      </template>
      <template slot="desc2">
        <budget
          @activeSteps="getActiveSteps"
          :step2.sync="step2"
          :pro_filter="step3.pro_filter"
        ></budget>
      </template>
      <template slot="desc3">
        <filter-setting
          @getFilter="getFilter"
          :step_3.sync="step3"
          :target_audience.sync="step3.target_audience"
          :pro_filter.sync="step3.pro_filter"
        ></filter-setting>
      </template>
    </v-steps>
    <el-button
      style="display: block; margin: 0 auto"
      type="primary"
      @click="goNext"
    >
      儲存
    </el-button>
  </div>
</template>
<script>
import QuestionnaireForm from '@/components/questionnaire/Step1-QuestionnaireForm';
import Budget from '@/components/questionnaire/Step2-Budget';
import FilterSetting from '@/components/questionnaire/Step3-FilterSetting';
import VSteps from '@/components/common/VSteps';
import { saveQuestionnaire, getDetail } from '@/api/questionnaire';
import _isEmpty from 'lodash/isEmpty';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'create-questionnaire',
  components: {
    QuestionnaireForm,
    Budget,
    FilterSetting,
    VSteps,
  },
  data() {
    return {
      isGetData: false,
      hasError: false,
      stepsList: [
        { title: '問卷設定' },
        { title: '費用評估' },
        { title: '受眾設定' },
      ],
      activeSteps: 1,
      step1: {
        update_datetime: new Date(),
        title: '',
        edition_type: 0,
        image: '',
        is_show_result: 0, // 沒用到
        project_name: '', // 沒用到
        content: '',
        thank_word: '',
        thank_image: '',
      },
      step2: {
        deliver_count: 0,
        estimated_cost: 0,
        budget_limit: 0,
        feedback_point: 0,
        recover_copy_limit: 0,
        cost_assessment_type: false, //是否依份數評估
        over_budget: false,
      },
      step3: {
        // list: {},
        target_audience: 0,
        positive_filter_setting: '',
        exclude_filter_setting: '',
        pro_filter: 0,
      },
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.getAllDetail();
    } else {
      this.isGetData = true;
    }
  },
  computed: {
    ...mapGetters(['getQuestionnaire']),
  },
  watch: {
    step1: {
      handler: 'checkChange',
      deep: true,
    },
    step2: {
      handler: 'checkChange',
      deep: true,
    },
    step3: {
      handler: 'checkChange',
      deep: true,
    },
  },
  methods: {
    ...mapMutations(['SET_CURRENT_QUESTIONNAIRE', 'SET_CHANGE_INFO']),
    ...mapActions(['toggleActiveTab']),
    getAllDetail() {
      getDetail(this.$route.query.id).then((res) => {
        // console.log(res);
        if (res.status === 0 || res.status === 3) {
          this.SET_CURRENT_QUESTIONNAIRE(res);
          this.step1 = {
            title: res.title,
            edition_type: res.edition_type,
            image: res.image,
            is_show_result: res.is_show_result, // 沒用到
            project_name: res.project_name, // 沒用到
            content: res.content,
            thank_word: res.thank_word,
            thank_image: res.thank_image,
          };
          this.step2 = {
            deliver_count: res.deliver_count,
            estimated_cost: res.estimated_cost || 0,
            budget_limit: res.budget_limit,
            feedback_point: res.feedback_point,
            recover_copy_limit: res.recover_copy_limit,
            cost_assessment_type: res.cost_assessment_type,
            over_budget: res.over_budget || false,
          };
          this.step3 = {
            // list: {},
            target_audience: res.target_audience || 0,
            positive_filter_setting: res.positive_filter_setting,
            exclude_filter_setting: res.exclude_filter_setting,
            pro_filter: res.pro_filter,
          };
          this.isGetData = true;
        } else {
          this.$message.error('該問卷無法編輯');
          this.$router.push('/questionnaire-list');
        }
      });
    },
    getActiveSteps(num) {
      this.activeSteps = num;
    },
    getFilter(filterForwardList, filterBackwardList) {
      this.step3.positive_filter_setting = _isEmpty(filterForwardList)
        ? ''
        : JSON.stringify(filterForwardList);
      this.step3.exclude_filter_setting = _isEmpty(filterBackwardList)
        ? ''
        : JSON.stringify(filterBackwardList);
    },
    //偵測input改變
    checkChange() {
      if (this.$route.query.id) {
        let form = { ...this.step1, ...this.step2, ...this.step3 };
        delete form.over_budget;
        delete form.target_audience;
        delete form.estimated_cost;
        let keys = Object.keys(form);
        const equal = keys.every((el) => {
          return form[el] == this.getQuestionnaire[el];
        });
        this.SET_CHANGE_INFO(!equal);
      }
    },
    //儲存問卷資訊
    async goNext() {
      let form = { ...this.step1, ...this.step2, ...this.step3 };
      //檢查網址有沒有id，沒有代表是新增帶id=0
      form.id = this.$route.query.id || 0;
      form.update_datetime = new Date();
      console.log(form);
      //檢查問卷資訊的必填項目
      let checkForm = [
        { text: '問卷標題', val: this.step1.title },
        {
          text: '預算/份數上限',
          val: this.step2.cost_assessment_type
            ? this.step2.recover_copy_limit
            : this.step2.budget_limit,
        },
        {
          text: '回饋點數',
          val: this.step2.feedback_point,
        },
        {
          text: '受眾篩選',
          val: this.step3.target_audience,
        },
      ];
      const noFilled = checkForm.filter(function (item, index, array) {
        if (item.val === '' || item.val === 0) return item.text;
      });
      if (noFilled.length > 0) {
        let text = [];
        noFilled.forEach((el) => {
          text.push(el.text);
        });
        this.$alert(`尚未設定${text}，請檢查上述的必填項目`, '必填未完成', {
          type: 'error',
          confirmButtonText: '確定',
        }).catch((e) => e);
        return false;
      }
      if (this.step2.budget_limit > 0 && this.step2.recover_copy_limit === 0) {
        this.$alert(`預算/預計回收份數不足`, '設定錯誤', {
          type: 'error',
          confirmButtonText: '確定',
        }).catch((e) => e);
        return false;
      }
      //檢查字數有沒有超過限制
      if (this.hasError) {
        this.$alert(`請再次檢查紅色框內的字數`, '字數超過限制', {
          type: 'error',
          confirmButtonText: '確定',
        })
          .then(() => {
            document.querySelector('main').scrollTo(0, 0);
          })
          .catch(() => {
            document.querySelector('main').scrollTo(0, 0);
          });
        return false;
      }
      if (this.step1.title.length > 30) {
        this.$alert(`問卷標題超過30個字`, '設定錯誤', {
          type: 'error',
          confirmButtonText: '確定',
        })
          .then(() => {
            document.querySelector('main').scrollTo(0, 0);
          })
          .catch(() => {
            document.querySelector('main').scrollTo(0, 0);
          });
        return false;
      }
      //檢查推播問卷的總受眾人數須大於推播問卷數
      if (this.step3.target_audience < this.step2.deliver_count) {
        this.$alert(
          `選取受眾人數須大於首次推播人數，請重新篩選受眾條件`,
          'ERROR!',
          {
            type: 'error',
            confirmButtonText: '確定',
          }
        ).catch((e) => e);
        return false;
      }
      //檢查有沒有超出預算
      if (this.step2.over_budget) {
        this.$alert(`預估費用超出預算，請調整預算上限或回饋點數`, 'ERROR!', {
          type: 'error',
          confirmButtonText: '確定',
        }).catch((e) => e);
        return false;
      }
      //儲存問卷資訊API
      const res = await saveQuestionnaire(form).catch((error) => {
        this.$alert(error.message, 'ERROR!', {
          type: 'error',
          confirmButtonText: '確定',
        });
      });
      if (!res) return false;
      if (!this.$route.query.id) {
        //新增問卷資訊
        this.$router.push(`/create-questionnaire?id=${res.id}`);
        //更新store裡的currentQuestionnaire資料
        //因參數傳送格式有差異，統一再從後端撈資料後放入currentQuestionnaire
        getDetail(res.id).then((res) => {
          // console.log(res);
          this.SET_CURRENT_QUESTIONNAIRE(res);
        });
        this.$alert(
          `您已新增一份新的問卷-${form.title}，可以開始編輯問卷題目`,
          '問卷新增成功',
          {
            type: 'success',
            confirmButtonText: '編輯題目',
            callback: () => {
              this.toggleActiveTab('2');
            },
          }
        );
      } else {
        //修改問卷資訊
        this.$message.success(res.message);
        this.SET_CHANGE_INFO(false);
        //因參數傳送格式有差異，統一再從後端撈資料後放入SET_CURRENT_QUESTIONNAIRE
        getDetail(res.id).then((res) => {
          this.SET_CURRENT_QUESTIONNAIRE(res);
        });
      }
    },
  },
};
</script>
