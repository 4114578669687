<template>
  <el-backtop class="back-to-top" target=".el-main" :visibility-height="10">
    <div><i class="el-icon-arrow-up"></i></div>
  </el-backtop>
</template>

<script>
export default {
  name: 'back-to-top',
};
</script>

<style scoped>
.back-to-top {
  background-color: #b0afb0;
  color: #fff;
}
</style>
