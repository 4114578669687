<template>
  <section>
    <el-row type="flex" align="middle">
      <el-col :span="15">
        <section>
          <div class="radioButton">
            <label>
              <input type="radio" v-model="step3.radio" :value="0" />
              <span></span>
              <section class="require">篩選受眾</section>
            </label>
            <div>
              <el-button type="info" @click="dialog = true">篩選</el-button>
            </div>
            <div class="link-grey" @click="clearOptions">清除條件</div>
          </div>

          <div class="content content-col">
            <div style="padding: 7px 0">
              <!-- {{ filterText }} -->
            </div>
            <div class="content-row" style="padding: 10px 0">
              <div style="margin-right: 70px">
                受眾總人數：
                <span v-price-format="userTotalCount"></span>
                人
              </div>
              <div>
                選取受眾人數：
                <span v-price-format="target_audience"></span>
                人
              </div>
            </div>
          </div>
        </section>
      </el-col>
      <el-col :span="1">
        <el-divider direction="vertical"></el-divider>
      </el-col>
      <el-col :span="8" style="align-self: flex-start; margin-top: 30px">
        <section class="count">
          <div class="title">選取受眾人數</div>
          <div class="number">
            <div v-price-format="target_audience"></div>
            <span>人</span>
          </div>
        </section>
      </el-col>
    </el-row>

    <!--  篩選受眾Dialog    -->
    <el-dialog
      :visible.sync="dialog"
      :center="true"
      width="90%"
      @close="dialog = false"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="dialog-title">。選取受眾人數</div>
          <el-divider></el-divider>

          <section class="statistics">
            <div>
              <div>統計人數</div>
              <span v-price-format="target_audience"></span>
            </div>
            <div>
              受眾總人數
              <span v-price-format="userTotalCount"></span>
            </div>
          </section>
          <el-form
            :model="form"
            label-position="right"
            label-width="15%"
            size="small"
          >
            <div class="dialog-title">
              。人口統計
              <el-popover
                placement="right"
                :visible-arrow="false"
                trigger="hover"
                popper-class="popoverBg"
              >
                <img
                  slot="reference"
                  src="../../assets/image/icon-info.png"
                  width="22"
                  style="margin-left: 16px"
                />
                <div>
                  1. 自填為受測者自願揭露訊息。
                  <br />
                  2. 預測為根據受測者消費足跡推估。
                </div>
              </el-popover>
            </div>
            <el-divider></el-divider>
            <el-form-item label="性別">
              <el-select
                v-model="form.gender.gender_type"
                value-key="id"
                placeholder="自填性別"
                @focus="element_select = true"
                @change="
                  validateField('gender_btn_disabled', [
                    form.gender.gender_type,
                    form.gender.value,
                  ])
                "
              >
                <el-option
                  label="自填性別"
                  :value="{ label: '自填性別', id: 0 }"
                  :disabled="select_disable.gender.manual"
                ></el-option>
                <el-option
                  label="預測性別"
                  :value="{ label: '預測性別', id: 1 }"
                  :disabled="select_disable.gender.predict"
                ></el-option>
              </el-select>
              <el-select
                v-model="form.gender.value"
                placeholder="請選擇"
                value-key="id"
                class="small-select"
                @focus="element_select = true"
                @change="
                  validateField('gender_btn_disabled', [
                    form.gender.gender_type,
                    form.gender.value,
                  ])
                "
              >
                <el-option
                  label="男性"
                  :value="{ label: '男性', id: 1 }"
                ></el-option>
                <el-option
                  label="女性"
                  :value="{ label: '女性', id: 2 }"
                ></el-option>
              </el-select>
              <div class="btn-wrap">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增正向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-plus.png"
                    :class="{ disabled: validate.gender_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '性別',
                        form.gender,
                        `${form.gender.gender_type.label} , ${form.gender.value.label}`,
                        'gender',
                        { type: 'forwardList', list: forwardList }
                      )
                    "
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增負向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-minus.png"
                    :class="{ disabled: validate.gender_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '性別',
                        form.gender,
                        `${form.gender.gender_type.label} , ${form.gender.value.label}`,
                        'gender',
                        { type: 'backwardList', list: backwardList }
                      )
                    "
                  />
                </el-tooltip>
              </div>
            </el-form-item>

            <el-form-item label="年齡">
              <el-select
                v-model="form.age.age_type"
                value-key="id"
                placeholder="自填年齡"
                @focus="element_select = true"
                @change="
                  validateField('age_btn_disabled', [
                    form.age.age_type,
                    form.age.value,
                  ])
                "
              >
                <el-option
                  label="自填年齡"
                  :value="{ label: '自填年齡', id: 0 }"
                  :disabled="select_disable.age.manual"
                ></el-option>
                <el-option
                  label="預測年齡"
                  :value="{ label: '預測年齡', id: 1 }"
                  :disabled="select_disable.age.predict"
                ></el-option>
              </el-select>
              <el-select
                v-model="form.age.value"
                value-key="id"
                placeholder="請選擇"
                class="small-select"
                @focus="element_select = true"
                @change="
                  validateField('age_btn_disabled', [
                    form.age.age_type,
                    form.age.value,
                  ])
                "
              >
                <el-option
                  v-for="age in ageList"
                  :key="age.id"
                  :label="age.label"
                  :value="{
                    label: age.label,
                    id: age.id,
                  }"
                ></el-option>
              </el-select>
              <div class="btn-wrap">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增正向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-plus.png"
                    :class="{ disabled: validate.age_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '年齡',
                        form.age,
                        `${form.age.age_type.label} , ${form.age.value.label}`,
                        'age',
                        { type: 'forwardList', list: forwardList }
                      )
                    "
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增負向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-minus.png"
                    :class="{ disabled: validate.age_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '年齡',
                        form.age,
                        `${form.age.age_type.label} , ${form.age.value.label}`,
                        'age',
                        { type: 'backwardList', list: backwardList }
                      )
                    "
                  />
                </el-tooltip>
              </div>
            </el-form-item>

            <el-form-item label="生活區域">
              <el-select
                v-model="form.area.region"
                placeholder="請選擇"
                value-key="id"
                @focus="element_select = true"
                @change="getCity(form.area.region.id)"
              >
                <el-option
                  v-for="i in addressList"
                  :label="i.name"
                  :value="{ label: i.name, id: i.id }"
                  :key="i.id"
                ></el-option>
              </el-select>
              <el-select
                v-model="form.area.city"
                placeholder="請選擇"
                value-key="id"
                @focus="element_select = true"
                @change="getDistrictList(form.area.city.id)"
              >
                <el-option
                  v-for="j in cityList"
                  :key="j.name"
                  :label="j.name"
                  :value="{ label: j.name, id: j.id }"
                  :disabled="
                    form.area.region.id === 6 || form.area.region.id === 7
                  "
                ></el-option>
              </el-select>
              <el-select
                v-model="form.area.district"
                placeholder="請選擇"
                value-key="id"
                :disabled="
                  form.area.region.id === 6 || form.area.region.id === 7
                "
                @focus="element_select = true"
                @change="validateField('area_btn_disabled', [form.area.region])"
              >
                <el-option
                  v-for="k in districtList"
                  :label="k.name"
                  :value="{ label: k.name, id: k.id }"
                  :key="k.id"
                ></el-option>
              </el-select>
              <div class="btn-wrap">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增正向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-plus.png"
                    :class="{ disabled: validate.area_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '生活區域',
                        form.area,
                        form.area.region.id === 6 || form.area.region.id === 7
                          ? `${form.area.region.label}`
                          : `${form.area.region.label} , ${form.area.city.label} , ${form.area.district.label}`,
                        'area',
                        { type: 'forwardList', list: forwardList }
                      )
                    "
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增負向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-minus.png"
                    :class="{ disabled: validate.area_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '生活區域',
                        form.area,
                        form.area.region.id === 6 || form.area.region.id === 7
                          ? `${form.area.region.label}`
                          : `${form.area.region.label} , ${form.area.city.label} , ${form.area.district.label}`,
                        'area',
                        { type: 'backwardList', list: backwardList }
                      )
                    "
                  />
                </el-tooltip>
              </div>
            </el-form-item>

            <div class="dialog-title">
              。個人特質
              <el-popover
                placement="right"
                :visible-arrow="false"
                trigger="hover"
                width="300"
                popper-class="popoverBg"
              >
                <i
                  slot="reference"
                  class="el-icon-medal"
                  style="margin-left: 16px; color: #646464"
                ></i>
                <div>
                  進階篩選欄位，每新增一個條件，將以下列公式進行收費：
                  pro進階篩選(個) x pro進階服務費(5點/人) x 回收份數上限
                </div>
              </el-popover>
            </div>
            <el-divider></el-divider>
            <el-form-item
              label="個性化標籤"
              :class="{ 'bg-blue': form.feature.is_charge }"
            >
              <el-select
                v-model="form.feature.value"
                value-key="id"
                placeholder="請選擇"
                @focus="element_select = true"
                @change="
                  validateField('feature_btn_disabled', [form.feature.value])
                "
              >
                <el-option
                  v-for="feature in featureList"
                  :label="feature.name"
                  :value="{ label: feature.name, id: feature.id }"
                  :key="feature.id"
                ></el-option>
              </el-select>
              <div class="btn-wrap" style="margin-right: 0">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增正向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-plus.png"
                    :class="{ disabled: validate.feature_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '個性化標籤',
                        form.feature,
                        form.feature.value.label,
                        'feature',
                        { type: 'forwardList', list: forwardList }
                      )
                    "
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增負向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-minus.png"
                    :class="{ disabled: validate.feature_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '個性化標籤',
                        form.feature,
                        form.feature.value.label,
                        'feature',
                        { type: 'backwardList', list: backwardList }
                      )
                    "
                  />
                </el-tooltip>
              </div>
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :span="1">
          <el-divider direction="vertical" class="dialog-divider"></el-divider>
        </el-col>

        <el-col :span="11">
          <el-form
            :model="form"
            label-position="right"
            label-width="20%"
            size="small"
          >
            <div class="dialog-title">
              。消費行為
              <el-popover
                placement="right"
                :visible-arrow="false"
                trigger="hover"
                width="300"
                popper-class="popoverBg"
              >
                <i
                  slot="reference"
                  class="el-icon-medal"
                  style="margin-left: 16px; color: #646464"
                ></i>
                <div>
                  *進階篩選欄位，每新增一個條件，將以下列公式進行收費：
                  pro進階篩選(個) x pro進階服務費(5點/人) x 回收份數上限。
                  <br />
                  *消費通路習慣：於該消費通路消費2次以上受眾。
                  <br />
                  *品牌習慣：消費該品牌2次以上受眾。
                </div>
              </el-popover>
            </div>
            <el-divider></el-divider>
            <el-form-item
              label="消費力"
              :class="{ 'bg-blue': form.spend_level.is_charge }"
            >
              <el-select
                v-model="form.spend_level.value"
                value-key="id"
                placeholder="請選擇"
                @focus="element_select = true"
                @change="
                  validateField('spend_level_btn_disabled', [
                    form.spend_level.value,
                  ])
                "
              >
                <el-option
                  label="高"
                  :value="{ label: '高', id: 3 }"
                ></el-option>
                <el-option
                  label="中"
                  :value="{ label: '中', id: 2 }"
                ></el-option>
                <el-option
                  label="低"
                  :value="{ label: '低', id: 1 }"
                ></el-option>
              </el-select>
              <div class="btn-wrap">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增正向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-plus.png"
                    :class="{ disabled: validate.spend_level_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '消費力',
                        form.spend_level,
                        form.spend_level.value.label,
                        'spend_level',
                        { type: 'forwardList', list: forwardList }
                      )
                    "
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增負向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-minus.png"
                    :class="{ disabled: validate.spend_level_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '消費力',
                        form.spend_level,
                        form.spend_level.value.label,
                        'spend_level',
                        { type: 'backwardList', list: backwardList }
                      )
                    "
                  />
                </el-tooltip>
              </div>
            </el-form-item>

            <el-form-item
              label="消費通路習慣"
              :class="{ 'bg-blue': form.channel.is_charge }"
            >
              <el-select
                v-model="form.channel.value"
                placeholder="請選擇"
                value-key="id"
                @focus="element_select = true"
                @change="
                  validateField('channel_btn_disabled', [form.channel.value])
                "
              >
                <el-option-group
                  v-for="group in channelList"
                  :label="group.label"
                  :key="group.key"
                >
                  <el-option
                    v-for="i in group.options"
                    :label="i.label"
                    :value="i.value"
                    :key="i.id"
                  ></el-option>
                </el-option-group>
              </el-select>
              <div class="btn-wrap">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增正向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-plus.png"
                    :class="{ disabled: validate.channel_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '消費通路習慣',
                        form.channel,
                        form.channel.value.label,
                        'channel',
                        { type: 'forwardList', list: forwardList }
                      )
                    "
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增負向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-minus.png"
                    :class="{ disabled: validate.channel_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '消費通路習慣',
                        form.channel,
                        form.channel.value.label,
                        'channel',
                        { type: 'backwardList', list: backwardList }
                      )
                    "
                  />
                </el-tooltip>
              </div>
            </el-form-item>

            <el-form-item
              label="品牌習慣"
              :class="{ 'bg-blue': form.brand.is_charge }"
            >
              <el-select
                :default-first-option="false"
                :filterable="true"
                v-model="form.brand.value"
                value-key="id"
                placeholder="請點擊選擇或輸入關鍵字"
                @focus="element_select = true"
                @change="
                  validateField('brand_btn_disabled', [form.brand.value])
                "
              >
                <el-option
                  v-for="i in brandList"
                  :label="i.name"
                  :value="{ label: i.name, id: i.id }"
                  :key="i.id"
                ></el-option>
              </el-select>
              <div class="btn-wrap">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增正向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-plus.png"
                    :class="{ disabled: validate.brand_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '品牌習慣',
                        form.brand,
                        form.brand.value.label,
                        'brand',
                        { type: 'forwardList', list: forwardList }
                      )
                    "
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增負向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-minus.png"
                    :class="{ disabled: validate.brand_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '品牌習慣',
                        form.brand,
                        form.brand.value.label,
                        'brand',
                        { type: 'backwardList', list: backwardList }
                      )
                    "
                  />
                </el-tooltip>
              </div>
            </el-form-item>

            <el-form-item
              label="消費品項"
              :class="{ 'bg-blue': form.item_tag.is_charge }"
            >
              <nest-select
                :value="form.item_tag.value"
                @getVal="handleItemTag"
                :element_select.sync="element_select"
              ></nest-select>
              <div class="btn-wrap">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增正向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-plus.png"
                    :class="{ disabled: validate.item_tag_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '消費品項',
                        form.item_tag,
                        form.item_tag.value,
                        'item_tag',
                        { type: 'forwardList', list: forwardList }
                      )
                    "
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增負向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-minus.png"
                    :class="{ disabled: validate.item_tag_btn_disabled }"
                    @click="
                      handleOptionsAdd(
                        '消費品項',
                        form.item_tag,
                        form.item_tag.value,
                        'item_tag',
                        { type: 'backwardList', list: backwardList }
                      )
                    "
                  />
                </el-tooltip>
              </div>
            </el-form-item>

            <el-form-item
              label="累計中獎次數"
              :class="{
                'bg-blue': form.invoice_accumulation_win_times.is_charge,
              }"
            >
              <el-select
                v-model="form.invoice_accumulation_win_times.year"
                placeholder="年份"
                value-key="id"
                @focus="element_select = true"
                @change="
                  validateField('invoice_accumulation_win_times_btn_disabled', [
                    form.invoice_accumulation_win_times.year,
                    form.invoice_accumulation_win_times.period,
                    form.invoice_accumulation_win_times.times,
                  ])
                "
              >
                <el-option
                  label="所有年份"
                  :value="{ label: '所有年份', id: 0 }"
                ></el-option>
                <el-option
                  label="2019年"
                  :value="{ label: '2019年', id: 2019 }"
                ></el-option>
                <el-option
                  label="2020年"
                  :value="{ label: '2020年', id: 2020 }"
                ></el-option>
              </el-select>
              <el-select
                v-model="form.invoice_accumulation_win_times.period"
                value-key="id"
                placeholder="期別"
                @focus="element_select = true"
                @change="
                  validateField('invoice_accumulation_win_times_btn_disabled', [
                    form.invoice_accumulation_win_times.year,
                    form.invoice_accumulation_win_times.period,
                    form.invoice_accumulation_win_times.times,
                  ])
                "
              >
                <el-option
                  v-for="i in periodList"
                  :value="i"
                  :label="i.label"
                  :key="i.label"
                ></el-option>
              </el-select>
              <el-select
                v-model="form.invoice_accumulation_win_times.times"
                placeholder="次數"
                value-key="id"
                @focus="element_select = true"
                @change="
                  validateField('invoice_accumulation_win_times_btn_disabled', [
                    form.invoice_accumulation_win_times.year,
                    form.invoice_accumulation_win_times.period,
                    form.invoice_accumulation_win_times.times,
                  ])
                "
              >
                <el-option
                  v-for="k in timesList"
                  :value="k"
                  :label="k.label"
                  :key="k.label"
                ></el-option>
              </el-select>
              <div class="btn-wrap">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增正向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-plus.png"
                    :class="{
                      disabled:
                        validate.invoice_accumulation_win_times_btn_disabled,
                    }"
                    @click="
                      handleOptionsAdd(
                        '累計中獎次數',
                        form.invoice_accumulation_win_times,
                        `${form.invoice_accumulation_win_times.year.label} , ${form.invoice_accumulation_win_times.period.label} , ${form.invoice_accumulation_win_times.times.label}`,
                        'invoice_accumulation_win_times',
                        { type: 'forwardList', list: forwardList }
                      )
                    "
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增負向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-minus.png"
                    :class="{
                      disabled:
                        validate.invoice_accumulation_win_times_btn_disabled,
                    }"
                    @click="
                      handleOptionsAdd(
                        '累計中獎次數',
                        form.invoice_accumulation_win_times,
                        `${form.invoice_accumulation_win_times.year.label} , ${form.invoice_accumulation_win_times.period.label} , ${form.invoice_accumulation_win_times.times.label}`,
                        'invoice_accumulation_win_times',
                        { type: 'backwardList', list: backwardList }
                      )
                    "
                  />
                </el-tooltip>
              </div>
            </el-form-item>

            <el-form-item
              label="累計中獎金額"
              :class="{
                'bg-blue': form.invoice_accumulation_win_money.is_charge,
              }"
            >
              <el-select
                v-model="form.invoice_accumulation_win_money.year"
                placeholder="年份"
                value-key="id"
                @focus="element_select = true"
                @change="
                  validateField('invoice_accumulation_win_money_btn_disabled', [
                    form.invoice_accumulation_win_money.year,
                    form.invoice_accumulation_win_money.period,
                    form.invoice_accumulation_win_money.amount,
                  ])
                "
              >
                <el-option
                  label="所有年份"
                  :value="{ label: '所有年份', id: 0 }"
                ></el-option>
                <el-option
                  label="2019年"
                  :value="{ label: '2019年', id: 2019 }"
                ></el-option>
                <el-option
                  label="2020年"
                  :value="{ label: '2020年', id: 2020 }"
                ></el-option>
              </el-select>
              <el-select
                v-model="form.invoice_accumulation_win_money.period"
                placeholder="期別"
                value-key="id"
                @change="
                  validateField('invoice_accumulation_win_money_btn_disabled', [
                    form.invoice_accumulation_win_money.year,
                    form.invoice_accumulation_win_money.period,
                    form.invoice_accumulation_win_money.amount,
                  ])
                "
              >
                <el-option
                  v-for="i in periodList"
                  :value="i"
                  :label="i.label"
                  :key="i.label"
                ></el-option>
              </el-select>
              <el-select
                v-model="form.invoice_accumulation_win_money.amount"
                placeholder="金額(元)"
                value-key="id"
                @focus="element_select = true"
                @change="
                  validateField('invoice_accumulation_win_money_btn_disabled', [
                    form.invoice_accumulation_win_money.year,
                    form.invoice_accumulation_win_money.period,
                    form.invoice_accumulation_win_money.amount,
                  ])
                "
              >
                <el-option
                  v-for="i in amountList"
                  :value="i"
                  :label="i.label"
                  :key="i.label"
                ></el-option>
              </el-select>
              <div class="btn-wrap">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增正向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-plus.png"
                    :class="{
                      disabled:
                        validate.invoice_accumulation_win_money_btn_disabled,
                    }"
                    @click="
                      handleOptionsAdd(
                        '累計中獎金額',
                        form.invoice_accumulation_win_money,
                        `${form.invoice_accumulation_win_money.year.label} , ${form.invoice_accumulation_win_money.period.label} , ${form.invoice_accumulation_win_money.amount.label}`,
                        'invoice_accumulation_win_money',
                        { type: 'forwardList', list: forwardList }
                      )
                    "
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增負向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-minus.png"
                    :class="{
                      disabled:
                        validate.invoice_accumulation_win_money_btn_disabled,
                    }"
                    @click="
                      handleOptionsAdd(
                        '累計中獎金額',
                        form.invoice_accumulation_win_money,
                        `${form.invoice_accumulation_win_money.year.label} , ${form.invoice_accumulation_win_money.period.label} , ${form.invoice_accumulation_win_money.amount.label}`,
                        'invoice_accumulation_win_money',
                        { type: 'backwardList', list: backwardList }
                      )
                    "
                  />
                </el-tooltip>
              </div>
            </el-form-item>

            <el-form-item
              label="單張中獎金額"
              :class="{ 'bg-blue': form.invoice_single_win_money.is_charge }"
            >
              <el-select
                v-model="form.invoice_single_win_money.year"
                placeholder="年份"
                value-key="id"
                @focus="element_select = true"
                @change="
                  validateField('invoice_single_win_money_btn_disabled', [
                    form.invoice_single_win_money.year,
                    form.invoice_single_win_money.period,
                    form.invoice_single_win_money.prize,
                  ])
                "
              >
                <el-option
                  label="所有年份"
                  :value="{ label: '所有年份', id: 0 }"
                ></el-option>
                <el-option
                  label="2019年"
                  :value="{ label: '2019年', id: 2019 }"
                ></el-option>
                <el-option
                  label="2020年"
                  :value="{ label: '2020年', id: 2020 }"
                ></el-option>
              </el-select>
              <el-select
                v-model="form.invoice_single_win_money.period"
                placeholder="期別"
                value-key="id"
                @focus="element_select = true"
                @change="
                  validateField('invoice_single_win_money_btn_disabled', [
                    form.invoice_single_win_money.year,
                    form.invoice_single_win_money.period,
                    form.invoice_single_win_money.prize,
                  ])
                "
              >
                <el-option
                  v-for="i in periodList"
                  :value="i"
                  :label="i.label"
                  :key="i.label"
                ></el-option>
              </el-select>
              <el-select
                v-model="form.invoice_single_win_money.prize"
                placeholder="金額(元)"
                value-key="id"
                @focus="element_select = true"
                @change="
                  validateField('invoice_single_win_money_btn_disabled', [
                    form.invoice_single_win_money.year,
                    form.invoice_single_win_money.period,
                    form.invoice_single_win_money.prize,
                  ])
                "
              >
                <el-option-group label="一般獎(元)">
                  <el-option
                    :value="{ label: '1,000萬', id: 10000000 }"
                    label="1,000萬"
                  ></el-option>
                  <el-option
                    :value="{ label: '200萬', id: 2000000 }"
                    label="200萬"
                  ></el-option>
                  <el-option
                    :value="{ label: '20萬', id: 200000 }"
                    label="20萬"
                  ></el-option>
                  <el-option
                    :value="{ label: '4萬', id: 40000 }"
                    label="4萬"
                  ></el-option>
                  <el-option
                    :value="{ label: '1萬', id: 10000 }"
                    label="1萬"
                  ></el-option>
                  <el-option
                    :value="{ label: '4千', id: 4000 }"
                    label="4千"
                  ></el-option>
                  <el-option
                    :value="{ label: '1千', id: 1000 }"
                    label="1千"
                  ></el-option>
                  <el-option
                    :value="{ label: '2百', id: 200 }"
                    label="2百"
                  ></el-option>
                </el-option-group>
                <el-option-group label="專屬獎(元)">
                  <el-option
                    :value="{ label: '100萬', id: 1000000 }"
                    label="100萬"
                  ></el-option>
                  <el-option
                    :value="{ label: '2千', id: 2000 }"
                    label="2千"
                  ></el-option>
                  <el-option
                    :value="{ label: '5百', id: 500 }"
                    label="5百"
                  ></el-option>
                </el-option-group>
              </el-select>
              <div class="btn-wrap">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增正向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-plus.png"
                    :class="{
                      disabled: validate.invoice_single_win_money_btn_disabled,
                    }"
                    @click="
                      handleOptionsAdd(
                        '單張中獎金額',
                        form.invoice_single_win_money,
                        `${form.invoice_single_win_money.year.label} , ${form.invoice_single_win_money.period.label} , ${form.invoice_single_win_money.prize.label}`,
                        'invoice_single_win_money',
                        { type: 'forwardList', list: forwardList }
                      )
                    "
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="新增負向條件"
                  placement="top-start"
                >
                  <img
                    class="button"
                    src="../../assets/image/icon-minus.png"
                    :class="{
                      disabled: validate.invoice_single_win_money_btn_disabled,
                    }"
                    @click="
                      handleOptionsAdd(
                        '單張中獎金額',
                        form.invoice_single_win_money,
                        `${form.invoice_single_win_money.year.label} , ${form.invoice_single_win_money.period.label} , ${form.invoice_single_win_money.prize.label}`,
                        'invoice_single_win_money',
                        { type: 'backwardList', list: backwardList }
                      )
                    "
                  />
                </el-tooltip>
              </div>
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :span="24">
          <div class="dash-hr"></div>
        </el-col>

        <el-col :span="24">
          <div class="dialog-title">
            。條件列表
            <el-popover
              placement="right"
              :visible-arrow="false"
              trigger="hover"
              popper-class="popoverBg"
            >
              <img
                slot="reference"
                src="../../assets/image/icon-info.png"
                width="22"
                style="margin-left: 16px"
              />
              <div>
                1.
                相同類別中的條件會優先完成篩選，此類別之條件結果才會與不同類別之條件結果再次篩選。
                <br />
                2.
                篩選之優先順序為先計算“且”關係的條件，並由上往下計算符合條件受眾人數。
                <br />
                3. 可於同性質條件欄位拖曳類調整上下篩選之順序。
                <br />
                4.
                若有加入排除條件，則為正向條件先算出結果，再扣除排除條件算出的結果。兩者獨立運算後再相減。
              </div>
            </el-popover>
          </div>

          <el-divider></el-divider>
        </el-col>

        <el-col :span="12" style="border-right: solid 1px #b0afb0">
          <div>
            正向條件列表
            <span>
              （請選擇上方條件後，點選
              <i
                class="el-icon-circle-plus"
                style="color: #1c63aa; font-size: 18px"
              ></i>
              新增至此）
            </span>
          </div>
          <div class="condition-wrap">
            <draggable v-model="forwardList" style="width: 80%">
              <section v-for="(item, index) in forwardList" :key="index">
                <div
                  v-show="forwardList.length !== 1 && index !== 0"
                  style="margin: 10px 0"
                >
                  <el-radio-group
                    v-model="item.groupConditionRadio"
                    size="small"
                    @change="questionnaireFilter(forwardList)"
                  >
                    <el-radio-button :label="0">且</el-radio-button>
                    <el-radio-button :label="1">或</el-radio-button>
                  </el-radio-group>
                </div>

                <div class="condition">
                  <div class="drag-btn">
                    <i class="el-icon-rank"></i>
                  </div>

                  <section
                    class="flex-col"
                    style="overflow: scroll; max-height: 200px; width: 100%"
                  >
                    <div
                      v-for="(i, itemIndex) in item.groupItem"
                      :key="itemIndex"
                    >
                      <div
                        style="
                          padding: 7px 30px;
                          display: flex;
                          flex-direction: column;
                        "
                        :class="{ 'bg-blue': item.is_charge }"
                      >
                        <div class="flex-col">
                          <div v-show="itemIndex === 0">{{ i.label }}</div>
                          <span>
                            {{ i.value.label }}
                            <img
                              src="../../assets/image/icon-cancle.png"
                              width="16"
                              @click="
                                handleDelete(
                                  { type: 'forwardList', list: forwardList },
                                  item,
                                  i
                                )
                              "
                            />
                          </span>
                        </div>
                      </div>

                      <div
                        v-show="item.groupItem.length !== itemIndex + 1"
                        style="padding-left: 30px"
                        :class="{ 'bg-blue': item.is_charge }"
                      >
                        <el-radio-group v-model="i.conditionRadio" size="small">
                          <!--<el-radio-button :label="0">且</el-radio-button>-->
                          <el-radio-button :label="1">或</el-radio-button>
                        </el-radio-group>
                      </div>
                    </div>
                  </section>
                </div>
                <!--<div
                    style="color: #1c63aa; display: flex; align-items: center"
                  >
                    <div @click="handleDelete(forwardList, item, i)"></div>
                    ｜
                    <span
                      class="link-blue"
                      @click="moveCondition(forwardList, backwardList, i)"
                    >
                      移至排除條件
                    </span>
                  </div>-->
              </section>
            </draggable>
          </div>
        </el-col>

        <el-col :span="11">
          <div class="flex-row">
            排除條件列表
            <span class="flex-row">
              （請選擇上方條件後，點選
              <img
                src="../../assets/image/icon-minus.png"
                width="16"
                style="margin: 5px"
              />
              新增至此）
            </span>
          </div>

          <div class="condition-wrap">
            <draggable v-model="backwardList" style="width: 80%">
              <section v-for="(item, index) in backwardList" :key="index">
                <div
                  v-show="backwardList.length !== 1 && index !== 0"
                  style="margin: 10px 0"
                >
                  <el-radio-group
                    v-model="item.groupConditionRadio"
                    size="small"
                    @change="questionnaireFilter(backwardList)"
                  >
                    <el-radio-button :label="0">且</el-radio-button>
                    <el-radio-button :label="1">或</el-radio-button>
                  </el-radio-group>
                </div>

                <div class="condition">
                  <div class="drag-btn">
                    <i class="el-icon-rank"></i>
                  </div>

                  <section
                    class="flex-col"
                    style="overflow: scroll; max-height: 200px; width: 100%"
                  >
                    <div
                      v-for="(i, itemIndex) in item.groupItem"
                      :key="itemIndex"
                    >
                      <div
                        style="
                          padding: 7px 30px;
                          display: flex;
                          flex-direction: column;
                        "
                        :class="{ 'bg-blue': item.is_charge }"
                      >
                        <div class="flex-col">
                          <div v-show="itemIndex === 0">{{ i.label }}</div>
                          <span>
                            {{ i.value.label }}
                            <img
                              src="../../assets/image/icon-cancle.png"
                              width="16"
                              @click="
                                handleDelete(
                                  { type: 'backwardList', list: backwardList },
                                  item,
                                  i
                                )
                              "
                            />
                          </span>
                        </div>
                      </div>

                      <div
                        v-show="item.groupItem.length !== itemIndex + 1"
                        style="margin-left: 30px"
                        :class="{ 'bg-blue': item.is_charge }"
                      >
                        <el-radio-group v-model="i.conditionRadio" size="small">
                          <!--<el-radio-button :label="0">且</el-radio-button>-->
                          <el-radio-button :label="1">或</el-radio-button>
                        </el-radio-group>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </draggable>
          </div>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="saveFilter">完成</el-button>
        <!-- <el-button type="primary" @click="saveFilter">確 定</el-button> -->
      </div>
    </el-dialog>
  </section>
</template>
<script>
import draggable from 'vuedraggable';
import NestSelect from '@/components/NestSelect';
import {
  getBrandList,
  getChannelList,
  getFeatureList,
  doQuestionnaireFilter,
  getTotalUserCount,
  getAreaRegionList,
  getAreaCityList,
  getAreaDistrictList,
} from '../../api/filterSetting';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';

export default {
  name: 'filterSetting',
  props: {
    step_3: Object,
    target_audience: Number,
    pro_filter: Number,
  },
  components: { NestSelect, draggable },
  data() {
    return {
      dialog: false,
      step3: {
        radio: 0,
        radio_exclude: '',
      },
      validate: {
        age_btn_disabled: true,
        gender_btn_disabled: true,
        area_btn_disabled: true,
        invoice_accumulation_win_times_btn_disabled: true,
        invoice_accumulation_win_money_btn_disabled: true,
        invoice_single_win_money_btn_disabled: true,
        spend_level_btn_disabled: true,
        item_tag_btn_disabled: true,
        channel_btn_disabled: true,
        brand_btn_disabled: true,
        feature_btn_disabled: true,
      },
      form: {
        age: { age_type: '', value: '', is_charge: false },
        gender: { gender_type: '', value: '', is_charge: false },
        area: { region: '', city: '', district: '', is_charge: false },
        invoice_accumulation_win_times: {
          year: '',
          period: '',
          times: '',
          is_charge: true,
        },
        invoice_accumulation_win_money: {
          year: '',
          period: '',
          amount: '',
          is_charge: true,
        },
        invoice_single_win_money: {
          year: '',
          period: '',
          prize: '',
          is_charge: true,
        },
        spend_level: { value: '', is_charge: true },
        item_tag: { value: '', is_charge: true },
        channel: { value: '', is_charge: true },
        brand: { value: '', is_charge: true },
        feature: { value: '', is_charge: true },
      },
      featureList: [],
      periodList: [
        { label: '1-2月', id: 0 },
        { label: '3-4月', id: 1 },
        { label: '5-6月', id: 2 },
        { label: '7-8月', id: 3 },
        { label: '9-10月', id: 4 },
        { label: '11-12月', id: 5 },
      ],
      timesList: [
        { label: '0', id: 0 },
        { label: '1-5', id: 1 },
        { label: '6-10', id: 2 },
        { label: '10次以上', id: 3 },
      ],
      amountList: [
        { label: '0', id: 0 },
        { label: '5,000以下', id: 1 },
        { label: '5,001-10,000', id: 2 },
        { label: '10,001-200,000', id: 3 },
        { label: '200,000-1,000,000', id: 4 },
        { label: '1,000,000-10,000,000', id: 5 },
        { label: '10,000,000以上', id: 6 },
      ],
      ageList: [
        { id: 1, label: '未成年(17歲含以下)' },
        { id: 2, label: '青年(18-30歲)' },
        { id: 3, label: '壯年(31-45歲)' },
        { id: 4, label: '中年(46-60歲)' },
        { id: 5, label: '老年(61歲以上)' },
      ],
      addressList: [],
      brandList: [],
      channelList: [
        { label: '實體店', key: 0, options: [] },
        { label: '線上購物', key: 1, options: [] },
      ],
      itemList: [],
      cityList: [],
      districtList: [],
      forwardList: [],
      backwardList: [],
      conditionRadio: 0,
      // target_audience: 0,
      userTotalCount: 0,
      filterTextArray: [],
      filterText: '',
      filter: {
        index: 0,
        positive_filter_setting: '',
        exclude_filter_setting: '',
      },
      charge_total_count: 0,
      //年齡、性別選項 disable
      select_disable: {
        age: {
          manual: false,
          predict: false,
        },
        gender: {
          manual: false,
          predict: false,
        },
      },
      element_select: false,
    };
  },
  mounted() {
    this.getSelectList();
  },
  watch: {
    step_3() {
      if (this.$route.query.id) {
        this.forwardList =
          this.step_3.positive_filter_setting !== '' &&
          this.step_3.positive_filter_setting !== 'null'
            ? JSON.parse(this.step_3.positive_filter_setting)
            : [];

        this.backwardList =
          this.step_3.exclude_filter_setting !== '' &&
          this.step_3.exclude_filter_setting !== 'null'
            ? JSON.parse(this.step_3.exclude_filter_setting)
            : [];
        this.questionnaireFilter();
      }
    },
    'forwardList.length'() {
      this.validateSelect(this.forwardList);
    },
    'backwardList.length'() {
      this.validateSelect(this.backwardList);
    },
  },
  methods: {
    getSelectList() {
      getAreaRegionList().then((res) => {
        this.addressList = res.data;
      });

      getBrandList().then((res) => {
        this.brandList = res.brand_list;
      });

      getChannelList().then((res) => {
        res.brand_list.forEach((i) => {
          if (i.is_online === 0) {
            this.channelList[0].options.push({
              label: i.name,
              value: { label: i.name, id: i.id, is_online: 0 },
            });
          } else {
            this.channelList[1].options.push({
              label: i.name,
              value: { label: i.name, id: i.id, is_online: 1 },
            });
          }
        });
      });

      getFeatureList().then((res) => {
        this.featureList = res.brand_list;
      });

      getTotalUserCount().then((res) => {
        this.userTotalCount = res.total_user;
      });
    },
    validateField(fieldName, fieldArray) {
      let isEmpty = fieldArray.some((i) => i === '');

      if (isEmpty) {
        this.validate[fieldName] = true;
      } else {
        this.validate[fieldName] = false;
      }
    },
    getCity(id) {
      getAreaCityList(id).then((res) => {
        this.cityList = res.data;
        this.form.area.city = '';
        this.form.area.district = '';

        if (this.form.area.region.id === 6 || this.form.area.region.id === 7) {
          this.validate['area_btn_disabled'] = false;
        } else {
          this.validate['area_btn_disabled'] = true;
        }
        this.validateField('area_btn_disabled', [this.form.area.region]);
      });
    },
    getDistrictList(id) {
      getAreaDistrictList(id).then((res) => {
        this.districtList = res.data;
        this.form.area.district = '';
        this.validateField('area_btn_disabled', [this.form.area.region]);
      });
    },
    handleSelected(item) {
      let selected = this.featureList
        .map((feature) => feature.name)
        .indexOf(item.name);
      this.featureList.splice(selected, 1);
      this.dynamicFeatures.push(item);
      this.form.feature = '';
    },
    handleItemTag(item, label) {
      this.form.item_tag = {
        ...this.form.item_tag,
        ...{
          value: label,
          id: item.id,
          rule_id: item.rule_id,
          tag_name: item.tag_name,
        },
      };
      this.validate.item_tag_btn_disabled = false;
    },
    saveFilter() {
      this.dialog = false;
      this.$emit('getFilter', this.forwardList, this.backwardList);
    },
    clearOptions() {
      this.forwardList = [];
      this.backwardList = [];
      this.filterText = '';
      this.charge_total_count = 0;
      this.$emit('update:target_audience', 0);
      this.$emit('update:pro_filter', 0);
      if (this.dialog) {
        this.dialog = false;
      }
      this.$emit('getFilter', this.forwardList, this.backwardList);
    },
    /**
     * 計算精準欄位數量
     */
    calcProFilter() {
      let all = [...this.forwardList, ...this.backwardList];

      let all_filter = _uniqBy(all, (i) => {
        return i.groupName;
      });

      let count = all_filter.filter((f) => f.is_charge === true);
      this.charge_total_count = count.length;
      this.$emit('update:pro_filter', this.charge_total_count);
    },
    handleDelete(source, groupItem, item) {
      if (
        groupItem.groupParameter === 'age' ||
        groupItem.groupParameter === 'gender'
      ) {
        const otherType =
          source.type === 'forwardList' ? 'backwardList' : 'forwardList';
        let hasItem = this[otherType].some(
          (e) => e.groupParameter === groupItem.groupParameter
        );
        //排除同向群組或另向還有該條件，不改變disable值
        if (groupItem.groupItem.length === 1 && !hasItem) {
          this.select_disable[groupItem.groupParameter].predict = false;
          this.select_disable[groupItem.groupParameter].manual = false;
        }
      }

      let index;
      if (groupItem.groupItem.length > 1) {
        index = groupItem.groupItem.findIndex((i) => i === item);
        groupItem.groupItem.splice(index, 1);
      } else {
        index = source.list.findIndex((i) => i === groupItem);
        source.list.splice(index, 1);
        if (
          item.parameter !== 'gender' &&
          item.parameter !== 'age' &&
          item.parameter !== 'area'
        ) {
          this.calcProFilter();
        }
      }
      this.$emit('update:target_audience', 0);
      this.questionnaireFilter(source.list);
    },
    /***
     *新增、移除條件
     * @param label 條件名稱
     * @param item 綁定條件的值
     * @param specVal 條件列表顯示文字
     * @param parameter parameterName
     * @param target 正向 or 負向
     */
    handleOptionsAdd(label, item, specVal, parameter, target) {
      const otherType =
        target.type === 'forwardList' ? 'backwardList' : 'forwardList';
      const indexInTarget = target.list.findIndex((e) => e.groupName === label);
      const indexInOther = this[otherType].findIndex(
        (e) => e.groupName === label
      );
      const hasItemInTarget = target.list[indexInTarget]?.groupItem.some(
        (e) => e.value.label === specVal
      );
      const hasItemInOther = this[otherType][indexInOther]?.groupItem.some(
        (e) => e.value.label === specVal
      );
      if (hasItemInTarget || hasItemInOther) {
        if (hasItemInTarget) {
          this.$message.error('此條件已存在於條件列表中');
        } else if (hasItemInOther) {
          this.$message.error('同一條件不可同時建立在正向和排除條件中');
        }
        return false;
      }
      let validate = Object.values(item).every((i) => i !== '');

      if (validate || parameter === 'area' || parameter === 'item_tag') {
        this.validate[`${parameter}_btn_disabled`] = true;
        // if (parameter === 'age' || parameter === 'gender') {
        //   if (item.value.id === 0) {
        //     this.select_disable[parameter].predict = true;
        //   } else {
        //     this.select_disable[parameter].manual = true;
        //   }
        // }
        if (parameter === 'area') {
          let c = item.city?.label ?? '';
          let d = item.district?.label ?? '';

          if (c === '') {
            specVal = item.region.label;
          } else if (d === '') {
            specVal = `${item.region.label} , ${item.city.label}`;
          } else {
            specVal = `${item.region.label} , ${item.city.label} , ${item.district.label}`;
          }

          // 都會、鄉村地區
          // if (item.district.label === '全部') {
          //   item.district = '';
          // } else if (item.region.id === 6 || item.region.id === 7) {
          //   item.city = this.cityList
          // }
        }

        let group_include = target.list.some((i) => i.groupName === label);
        //判斷 target 是否已存在同一條件
        if (group_include) {
          target.list.forEach((i) => {
            if (i.groupName === label) {
              i.text = specVal !== '' ? specVal : item.value.label;
              i.groupItem.push({
                parameter: parameter,
                item: item,
                label: label,
                value: specVal !== '' ? { label: specVal } : item.value,
                conditionRadio: 1,
              });
            }
          });
        } else {
          target.list.push({
            text: specVal !== '' ? specVal : item.value.label,
            groupParameter: parameter,
            groupName: label,
            groupConditionRadio: 0,
            groupItem: [
              {
                parameter: parameter,
                item: item,
                label: label,
                value: specVal !== '' ? { label: specVal } : item.value,
                conditionRadio: 1,
              },
            ],
            is_charge: item.is_charge,
          });

          if (
            parameter !== 'gender' &&
            parameter !== 'age' &&
            parameter !== 'area'
          ) {
            this.calcProFilter();
          }
        }

        this.$message.success('新增成功，請下滑查看');
        this.$data.form[parameter] = this.$options.data().form[parameter];

        this.questionnaireFilter(target.list);
      }
    },
    /**
     *
     * @param {{labelName}}_judge_type 不同條件間“且”，“或”，條件跟著後者(exp: gender && age age_judge_type)
     *
     */
    async questionnaireFilter() {
      this.filter.index += 1;

      this.filterTextArray = [];

      //  處理篩選條件顯示文字
      this.filterTextArray.forEach((k, index) => {
        let andor = this.filterTextArray.length === index + 1 ? '' : k.andOr;
        this.filterText += ` ${k.text} ${andor} `;
      });
      this.filter.positive_filter_setting = _isEmpty(this.forwardList)
        ? ''
        : JSON.stringify(this.forwardList);
      this.filter.exclude_filter_setting = _isEmpty(this.backwardList)
        ? ''
        : JSON.stringify(this.backwardList);
      await doQuestionnaireFilter(this.filter).then((res) => {
        // console.log(res.count)
        this.$emit('update:target_audience', res.count);
        //以下判斷寫不進去，待除bug------------wanwan 2021-10-04
        if (this.index === res.index) {
          this.target_audience = res.count;
        }
      });
      this.$emit('getFilter', this.forwardList, this.backwardList);
    },
    moveCondition(source, target, item) {
      let group_include = target.some((i) => i.groupName === item.label);

      source.forEach((n, idx) => {
        if (n.groupName === item.label) {
          let index = n.groupItem.findIndex((k) => k === item);
          n.groupItem.splice(index, 1);
          if (n.groupItem.length === 0) {
            source.splice(idx, 1);
          }
        }
      });
      if (group_include) {
        target.forEach((i) => {
          if (i.groupName === item.label) {
            i.groupItem.push(item);
          }
        });
      } else {
        target.push({
          groupName: item.label,
          groupItem: [item],
        });
      }
    },
    /***
     * 找篩選條件對應的type
     * @param p parameterName
     * @returns {{parameter, type}|*}
     */
    getFilterType(p) {
      let type_array = [
        {
          type: 1,
          parameter: 'gender',
        },
        {
          type: 2,
          parameter: 'age',
        },
        {
          type: 3,
          parameter: 'area',
        },
        {
          type: 4,
          parameter: 'feature',
        },
        {
          type: 5,
          parameter: 'spend_level',
        },
        {
          type: 6,
          parameter: 'channel',
        },
        {
          type: 7,
          parameter: 'brand',
        },
        {
          type: 8,
          parameter: 'item_tag',
        },
        {
          type: 9,
          parameter: 'invoice_accumulation_win_times',
        },
        {
          type: 10,
          parameter: 'invoice_accumulation_win_money',
        },
        {
          type: 11,
          parameter: 'invoice_single_win_money',
        },
      ];
      let index = type_array.findIndex((i) => i.parameter === p);
      return type_array[index];
    },
    /***
     * 設定年齡、性別（自填、預測）選項擇一
     * @param targetList （forwardList or backwardList）
     */
    validateSelect(targetList) {
      if (targetList.length !== 0) {
        targetList.forEach((i) => {
          if (i.groupParameter === 'age' || i.groupParameter === 'gender') {
            i.groupItem.forEach((el) => {
              if (el.item[`${i.groupParameter}_type`].id === 0) {
                this.select_disable[i.groupParameter].predict = true;
                this.select_disable[i.groupParameter].manual = false;
              } else {
                this.select_disable[i.groupParameter].manual = true;
                this.select_disable[i.groupParameter].predict = false;
              }
            });
          }
        });
      }
      // else {
      //   this.$data.select_disable = this.$options.data().select_disable;
      // }
    },
  },
};
</script>
<style type="text/scss" lang="scss" scoped>
/deep/ .el-select {
  width: 100%;

  .el-input {
    max-width: unset;
  }
}
.el-divider--vertical {
  height: 20em;
}

.el-divider--horizontal {
  margin: 6px 0 24px 0;
}

.small-select {
  max-width: 133px;
  margin-left: 12px;
}

.btn-wrap {
  margin: 0 10px 0 16px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.el-icon-plus:before {
  font-weight: bold;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.dialog-divider {
  height: 31.5em;
}

.statistics {
  margin: 22px 20px 34px 20px;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  div {
    &:first-child {
      color: black;
      margin-right: 30px;

      span {
        font-size: 30px;
        font-family: PingFangTC-Medium;
        color: black;
        margin: 12px 0;
      }
    }

    &:last-child {
      font-size: 14px;
    }
  }
}

.svg-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.require {
  position: relative;

  &:after {
    content: '*';
    color: #1c63aa;
    position: absolute;
    top: 0;
    left: -10px;
  }
}

.dash-hr {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, #1c63aa 50%, transparent 50%);
  background-size: 20px 1px;
  background-repeat: repeat-x;
  margin: 30px auto;
}

.button {
  width: 22px;
  height: 22px;

  &:hover {
    filter: opacity(0.7);
  }

  &:nth-child(1) {
    margin-right: 6px;
  }

  &.disabled {
    filter: opacity(0.5);
    pointer-events: none;
    cursor: not-allowed;
  }
}

.bg-blue {
  background: #ebeffa;
  padding: 8px 10px;
  margin-bottom: 0;
}

/deep/ .el-form-item__label {
  width: 20% !important;
}
</style>
