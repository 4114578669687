<template>
  <el-tabs
    type="border-card"
    v-model="activeTab"
    :before-leave="handleClick"
    style="font-family: PingFangTC-Regular"
  >
    <el-tab-pane label="問卷資訊" name="1">
      <questionnaire-setting>
        <backToTop></backToTop>
      </questionnaire-setting>
    </el-tab-pane>
    <el-tab-pane label="題目設定" name="2" :disabled="!this.$route.query.id">
      <create-question>
        <backToTop></backToTop>
      </create-question>
    </el-tab-pane>
    <el-tab-pane label="問卷發佈" name="3" :disabled="!this.$route.query.id">
      <questionnaire-release></questionnaire-release>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import CreateQuestion from '@/components/createQuestion/CreateQuestion';
import QuestionnaireSetting from '@/components/questionnaire/QuestionnaireSetting';
import QuestionnaireRelease from '@/components/questionnaire/QuestionnaireRelease';
import backToTop from '@/components/common/BackToTop.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import validateQuestion from '@/mixin/validateQuestion';

export default {
  name: 'create-questionnaire',
  mixins: [validateQuestion],
  components: {
    CreateQuestion,
    QuestionnaireSetting,
    QuestionnaireRelease,
    backToTop,
  },
  created() {
    if (!this.$route.query.id) {
      this.CLEAR_QUES();
      this.toggleActiveTab('1');
    } else {
      this.fetchQuestions(this.$route.query.id);
    }
    this.SET_CHANGE_ORDER(false);
    this.SET_CHANGE_INFO(false);
    // console.log(this.$route.query.id);
  },
  computed: {
    ...mapGetters(['getChangeOrder', 'getChangeInfo']),
    activeTab: {
      get() {
        return this.$store.getters.activeTab;
      },
      set(val) {
        document.querySelector('main').scrollTo(0, 0);
        return this.toggleActiveTab(val);
      },
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.activeTab == 2 && this.getChangeOrder) {
      const choose = await this.$confirm(
        '您尚未儲存目前的題目排序<br>離開後未儲存的資料將不會保存',
        '確定要離開嗎？',
        {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          type: 'warning',
        }
      )
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
      if (!choose) return false;
    }
    if (this.activeTab == 1 && this.getChangeInfo) {
      const choose = await this.$confirm(
        '您尚未儲存更新的問卷資訊<br>離開後未儲存的資料將不會保存',
        '確定要離開嗎？',
        {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          type: 'warning',
        }
      )
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
      if (!choose) return false;
    }
    next();
  },
  methods: {
    ...mapMutations(['CLEAR_QUES', 'SET_CHANGE_ORDER', 'SET_CHANGE_INFO']),
    ...mapActions(['toggleActiveTab', 'fetchQuestions']),
    handleClick(tabName) {
      this.toggleActiveTab(tabName);
      if (tabName !== '2') {
        const setValid = this.$_validateSetting([
          'checkGroupQuestion',
          'checkSaveSort',
        ]);
        if (!setValid) return false;
      }
      if (tabName !== '1') {
        const setValid = this.$_validateSetting(['checkSaveForm', 'checkInfo']);
        if (!setValid) return false;
      }
    },
  },
};
</script>
