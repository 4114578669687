<template>
  <div class="question-table">
    <!-- 是群組才顯示的部分 -->
    <div v-if="isGroup" class="group-style">
      <div class="group-header">
        <div class="group-name">
          題組名稱：{{ questions.name || '尚未設定' }}
          <el-link
            type="primary"
            class="self-link"
            @click="handleGroup('edit')"
          >
            編輯
          </el-link>
        </div>
        <div>
          <span class="tip">{{ getRange }}</span>
          <el-dropdown style="margin-right: 10px" @command="handleGroupAction">
            <el-button icon="el-icon-more" size="mini"></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="clone">複製題組</el-dropdown-item>
              <el-dropdown-item command="delete">刪除題組</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown
            ref="questionDrop"
            style="margin-right: 10px"
            trigger="click"
            @command="handleQuestionType($event, 'create')"
          >
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="mini"
              @click="checkFive(questions)"
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="i in questionType"
                :key="i.id"
                :command="i"
              >
                {{ i.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div v-if="questions.description.length > 0" class="group-description">
        {{ questions.description }}
      </div>
    </div>

    <!-- 題目item -->
    <div
      v-if="!isGroup"
      class="card-content"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div>
        <span :class="questions.is_required ? 'star' : 'hideStar'">*</span>
        {{ questions.sort }}
      </div>
      <div>{{ questions.type_description }}</div>
      <div>{{ questions.title }}</div>
      <div>
        {{ questions.update_datetime.split(' ')[0].split('/').join('-') }}
      </div>
      <div
        :class="hover ? 'question-btns' : 'question-btns hide'"
        style="text-align: right"
      >
        <el-button
          size="mini"
          type="danger"
          @click="handleRemoveQuestion(questions, nestedIndex, isChild)"
        >
          删除
        </el-button>
        <el-button size="mini" @click="handleCloneQuestion(questions)">
          複製
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="handleEditQuestion(questions)"
        >
          編輯
        </el-button>
      </div>
    </div>

    <template v-if="isGroup && questions.question_array.length < 1">
      <p class="group-placeholder" style="color: #b0afb0; text-align: center">
        點擊「＋」，
        <br />
        開始新增題組題目，最多五題
      </p>
    </template>

    <!-- 新增/編輯題目彈窗 -->
    <component
      :dialog-title="dialogQuestionType.name"
      :dialog-visible="showQuestionDialog"
      :is="dialogQuestionType.template"
      :type-id="dialogQuestionType.id"
      :with-image="dialogQuestionType.withImage"
      :image-per-option="dialogQuestionType.imagePerOption"
      :remark="dialogQuestionType.remark"
      :dialog-action="dialogAction"
      :question-info="questionInfo"
      @update:showQuestionDialog="showQuestionDialog = $event"
      @handleUpdateQuestion="
        handleUpdateQuestion($event, questions.group_id || questions.groupId)
      "
    ></component>

    <!-- 題組彈窗 -->
    <EditGroup
      :showGroupDialog.sync="showGroupDialog"
      :groupInfo="questions"
      :action="GroupDialogAction"
      @submitGroup="submitGroup($event, 'edit')"
    ></EditGroup>
  </div>
</template>
<script>
import GeneralType from '@/components/questionType/GeneralType';
import TextareaType from '@/components/questionType/TextareaType';
import EditGroup from '@/components/questionType/EditGroup';
import { mapGetters } from 'vuex';
import createQuestionTool from '@/mixin/createQuestionTool';

export default {
  name: 'QuestionTableItem',
  mixins: [createQuestionTool],
  components: { GeneralType, TextareaType, EditGroup },
  props: {
    questions: { type: undefined },
    nestedIndex: { type: [String, Number] },
    isChild: { type: Boolean },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    ...mapGetters(['getTempQuestionQueue']),
    //檢查是不是題組
    isGroup() {
      return !!this.questions.group_id;
    },
    //計算題組內的題目序號範圍
    getRange() {
      const c = this.questions.question_array;
      const start = c[0]?.sort || 0;
      const end = c[c.length - 1]?.sort || 0;
      return `第${start}題 ～ 第${end}題`;
    },
  },
  created() {
    // console.log('傳進來的值', this.questions);
    // console.log('問卷題目:', this.getTempQuestionQueue);
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
/deep/.special-group.collapse {
  .group-description,
  .nestable-list,
  .group-placeholder {
    display: none;
  }
}
.question-table {
  padding: 0 0 0 20px;
  direction: ltr;
  text-align: left;
}

.group-style {
  padding: 15px 0;
}

.group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .group-name {
    font-size: 20px;
    max-width: 75%;
  }
}

.group-description {
  margin: 15px 0 0;
  background-color: #f3f3f3;
  border: 1px solid #c2bfbf;
  color: #646464;
  padding: 5px;
}

.self-link {
  margin-left: 10px;
}

.tip {
  color: #909399;
  margin-right: 20px;
}
.star {
  color: #f56c6c;
}

.hideStar {
  color: transparent;
}

.card-content {
  display: grid;
  grid-template-columns: 1fr 2fr 4fr 2fr 3fr;
  align-items: center;
  line-break: anywhere;
  padding: 15px 0;
}

.question-btns {
  transition: 0.3s;
  opacity: 1;
}
.question-btns.hide {
  opacity: 0;
}
</style>
