//**********************處理題目題組所有相關功能**********************
import { mapActions, mapGetters } from 'vuex';
import { getQuestionDetail } from '@/api/questionBank';
import { dateTimeWithSlash } from '@/utils/datetimeFormat';

const createQuestionTool = {
  data() {
    return {
      questionType: [
        {
          id: 1,
          name: '單選題',
          template: 'GeneralType',
          withImage: true,
          remark: [
            '跳題可選擇該題組“以後”的題組或獨立單題題目。',
            '若有拖曳題目請務必於編輯頁檢查跳題設定，若有拖曳後跳題至前面題目將會失效。',
          ],
        },
        {
          id: 2,
          name: '直式單選題',
          template: 'GeneralType',
          withImage: true,
          remark: [
            '直式題選項固定5個或7個、選項文字限制6個字內。',
            '選項依照上至下順序，呈現在app畫面為由左至右排列，下載報表資料(選項序號)填答者選第一個選項資料呈現為1、第二個選項資料呈現為2，以此類推。',
            '跳題可選擇該題組“以後”的題組或獨立單題題目。',
            '若有拖曳題目請務必於編輯頁檢查跳題設定，若有拖曳後跳題至前面題目將會失效。',
          ],
        },
        {
          id: 3,
          name: '複選題',
          template: 'GeneralType',
          withImage: true,
          remark: ['複選題無法設定跳題。'],
        },
        {
          id: 13,
          name: '直式複選題',
          template: 'GeneralType',
          withImage: true,
          remark: [
            '直式題選項固定5個或7個、選項文字限制6個字內。',
            '選項依照上至下順序，呈現在app畫面為由左至右排列，下載報表資料(選項序號)填答者選第一個選項資料呈現為1、第二個選項資料呈現為2，以此類推。',
            '複選題無法設定跳題。',
          ],
        },
        {
          id: 4,
          name: '圖片題',
          template: 'GeneralType',
          imagePerOption: true,
          remark: [
            '建議新增4個以內選項避免問卷版面過長影響回收成效。',
            '跳題可選擇該題組“以後”的題組或獨立單題題目。',
            '若有拖曳題目請務必於編輯頁檢查跳題設定，若有拖曳後跳題至前面題目將會失效。',
          ],
        },
        // {
        //   id: 5,
        //   name: '是非題',
        //   template: 'TrueOrFalse',
        // },
        {
          id: 6,
          name: '簡答題',
          template: 'TextareaType',
          withImage: true,
        },
        {
          id: 7,
          name: '地址題',
          template: 'TextareaType',
        },
        {
          id: 8,
          name: '下拉題',
          template: 'GeneralType',
          withImage: true,
          remark: [
            '跳題可選擇該題組“以後”的題組或獨立單題題目。',
            '若有拖曳題目請務必於編輯頁檢查跳題設定，若有拖曳後跳題至前面題目將會失效。',
          ],
        },
        {
          id: 9,
          name: '影片題',
          template: 'GeneralType',
          remark: [
            '跳題可選擇該題組“以後”的題組或獨立單題題目。',
            '若有拖曳題目請務必於編輯頁檢查跳題設定，若有拖曳後跳題至前面題目將會失效。',
          ],
        },
        {
          id: 10,
          name: '評比題',
          template: 'TextareaType',
          withImage: true,
          remark: ['最高分5顆星，最低分1顆星'],
        },
        {
          id: 11,
          name: 'email題',
          template: 'TextareaType',
        },
        {
          id: 12,
          name: '生日題',
          template: 'TextareaType',
          remark: ['填答格式：西元年/月/日'],
        },
      ],
      showQuestionDialog: false,
      dialogQuestionType: {},
      dialogAction: '',
      questionInfo: {
        id: 0,
        title: '',
        content: '',
        image: null,
        is_required: true,
        max_select: 0,
        create_user: 0,
        update_user: 0,
        video_link: null,
        option_array: [],
        create_datetime: dateTimeWithSlash(new Date()),
        question_type: 1,
      },
      showGroupDialog: false,
      GroupDialogAction: '',
      groupInfo: {
        id: 0,
        name: '',
        description: '',
        question_array: [],
        bg_color: '#F7F7F7',
        create_datetime: dateTimeWithSlash(new Date()),
        question_type: 2,
      },
    };
  },
  computed: {
    ...mapGetters(['getTempQuestionQueue']),
  },
  methods: {
    ...mapActions([
      'createGroup',
      'updateGroup',
      'createQuestion',
      'updateQuestion',
      'createGroupQuestion',
      'updateGroupQuestion',
      'deleteQuestion',
      'cloneQuestion',
      'deleteGroup',
      'cloneGroup',
    ]),
    //
    resetInfo(type) {
      switch (type) {
        case 1:
          this.questionInfo = {
            id: 0,
            title: '',
            content: '',
            image: null,
            is_required: true,
            max_select: 0,
            create_user: 0,
            update_user: 0,
            video_link: null,
            option_array: [],
            create_datetime: dateTimeWithSlash(new Date()),
            question_type: 1,
          };
          break;
        case 2:
          this.groupInfo = {
            id: 0,
            name: '',
            description: '',
            question_array: [],
            bg_color: '#F7F7F7',
            create_datetime: dateTimeWithSlash(new Date()),
            question_type: 2,
          };
          break;
      }
    },

    //打開題組彈窗
    handleGroup(action) {
      this.resetInfo(2);
      this.GroupDialogAction = action;
      this.showGroupDialog = true;
    },

    //確認送出-新增/編輯題組
    submitGroup(group, action) {
      switch (action) {
        case 'create':
          this.createGroup({
            ...group,
            questionnaire_id: parseInt(this.$route.query.id),
            update_datetime: dateTimeWithSlash(new Date()),
          });
          break;
        case 'edit':
          {
            const question_array = this.questions.question_array.map((ques) => {
              return ques.id;
            });
            const data = {
              ...this.questions,
              question_array,
              update_datetime: dateTimeWithSlash(new Date()),
            };
            this.updateGroup(data);
          }
          break;
      }
    },

    //檢查題組有沒有滿5題
    checkFive(questions) {
      if (questions.changeOrder === true) {
        this.$alert('請先儲存目前的題目排序', 'ERROR!', {
          type: 'error',
          confirmButtonText: '確定',
        }).catch((e) => e);
        this.$refs.questionDrop.dropdownElm.hidden = true;
        return;
      }
      if (!this.isUnderFive(questions.group_id)) {
        this.$alert('題組內最多5個題目', 'ERROR!', {
          type: 'error',
          confirmButtonText: '確定',
        }).catch((e) => e);
        this.$refs.questionDrop.dropdownElm.hidden = true;
        return;
      }
      this.$refs.questionDrop.dropdownElm.hidden = false;
    },

    isUnderFive(groupId) {
      if (!groupId) return true;
      const target = this.getTempQuestionQueue.find(
        (item) => item.question_type === 2 && item.group_id === groupId
      );
      return target?.question_array?.length < 5;
    },

    //新增題目
    handleQuestionType(type, action) {
      this.resetInfo(1);
      this.dialogQuestionType = type;
      this.dialogAction = action;
      this.showQuestionDialog = true;
    },

    //編輯題目
    async handleEditQuestion(questions) {
      const res = await getQuestionDetail(questions.id);
      this.questionInfo = res;
      const type = this.questionType.find(
        (ques) => ques.id === this.questionInfo.type
      );
      this.dialogQuestionType = type;
      this.dialogAction = 'edit';
      this.showQuestionDialog = true;
    },

    //確認送出-新增/編輯題目
    handleUpdateQuestion(options, groupId) {
      // console.log('選項', options, '群組id', groupId);
      const baseData = {
        ...this.questionInfo,
        option_array: options || [],
        type: this.dialogQuestionType.id,
        questionnaire_id: parseInt(this.$route.query.id),
        type_description: this.dialogQuestionType.name,
        update_datetime: dateTimeWithSlash(new Date()),
      };

      switch (this.dialogAction) {
        case 'create':
          if (groupId) {
            //題組內新增題目
            this.createGroupQuestion({ ...baseData, groupId });
          } else {
            //外面新增題目
            this.createQuestion({ ...baseData, groupId: '' });
          }
          break;
        case 'edit':
          if (groupId) {
            //題組內編輯題目
            this.updateGroupQuestion({ ...baseData, groupId });
          } else {
            //外面編輯題目
            this.updateQuestion({ ...baseData, groupId: '' });
          }
          break;
      }
    },

    //刪除題目
    handleRemoveQuestion(value, nestedIndex, isChild) {
      this.$confirm(`確定要刪除題目「${value.title}」？`, '確認刪除', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const data = {
            id: value.id,
            groupId: value.groupId,
            nestedIndex,
            isChild,
          };
          this.deleteQuestion(data);
        })
        .catch((e) => e);
    },

    //複製群組＆刪除群組
    handleGroupAction(action) {
      if (action === 'clone') {
        if (this.questions.changeOrder === true) {
          this.$alert('請先儲存目前的題目排序', 'ERROR!', {
            type: 'error',
            confirmButtonText: '確定',
          }).catch((e) => e);
          return;
        }
        const data = {
          questionnaire_id: this.questions.questionnaire_id,
          group_id: this.questions.group_id,
        };
        this.cloneGroup(data);
      }

      if (action === 'delete') {
        this.$confirm(
          `確定要刪除題組「${this.questions.name}」？<br /><small>一旦刪除題組，所屬題目也將一起被刪除。</small>`,
          '確認刪除',
          {
            confirmButtonText: '確定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            type: 'warning',
          }
        )
          .then(() => {
            const data = {
              group_id: this.questions.group_id,
              nestedIndex: this.nestedIndex,
            };
            this.deleteGroup(data);
          })
          .catch((e) => e);
      }
    },

    //複製題目
    handleCloneQuestion(value) {
      // console.log(value);
      if (value.groupId && !this.isUnderFive(value.groupId)) {
        this.$alert('題組內最多5個題目', 'ERROR!', {
          type: 'error',
          confirmButtonText: '確定',
        }).catch((e) => e);
        return;
      }
      if (value.changeOrder === true) {
        this.$alert('請先儲存目前的題目排序', 'ERROR!', {
          type: 'error',
          confirmButtonText: '確定',
        }).catch((e) => e);
        return;
      }
      const data = {
        questionnaire_id: value.questionnaire_id,
        group_id: value.groupId,
        question_id: value.id,
      };
      this.cloneQuestion(data);
    },
  },
};

export default createQuestionTool;
