************處理題目列表的拖曳功能和計算排序************
<template>
  <div style="color: #767676">
    <div class="table-header drag-wrapper">
      <div class="card-content-header" style="padding-left: 20px">
        <div>排序</div>
        <div>題目類型</div>
        <div>題目標題</div>
        <div>更新時間</div>
        <div style="text-align: right; padding-right: 80px">功能</div>
      </div>
      <div>拖曳</div>
    </div>

    <VueNestable
      v-model="nestableItems"
      rtl
      :threshold="0"
      :max-depth="2"
      class-prop="class"
      key-prop="key"
      children-prop="question_array"
      @change="handleChange"
      :hooks="{
        beforeMove: beforeMove,
      }"
    >
      <div slot="placeholder">
        <p style="text-align: center; padding: 50px 0">
          目前沒有內容
          <br />
          可以點擊右上角新增題目囉～
        </p>
      </div>

      <template slot-scope="{ item, index, isChild }">
        <div class="drag-wrapper-rtl">
          <!-- Handler -->
          <VueNestableHandle :item="item">
            <em
              class="el-icon-sort"
              style="font-size: 18px; padding-top: 4px"
            ></em>
          </VueNestableHandle>

          <!-- Content -->
          <QuestionTableItem
            :questions="item"
            :nested-index="index"
            :is-child="isChild"
          ></QuestionTableItem>
        </div>
      </template>
    </VueNestable>
  </div>
</template>

<script>
import { VueNestable, VueNestableHandle } from 'vue-nestable';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import QuestionTableItem from '@/components/createQuestion/QuestionTableItem';

export default {
  components: {
    VueNestable,
    VueNestableHandle,
    QuestionTableItem,
  },
  data() {
    return {
      nestableItems: [],
      needAlert: true,
    };
  },

  computed: {
    ...mapGetters([
      'getServerQuestionQueue',
      'getTempQuestionQueue',
      'getQuestionnaireTitle',
      'getChangeOrder',
    ]),
  },

  watch: {
    getServerQuestionQueue: {
      handler() {
        this.getIndex();
      },
      deep: true,
    },
  },

  created() {
    this.getIndex();
    // console.log(this.getChangeOrder);
  },

  methods: {
    ...mapMutations(['SET_ORDER', 'SET_NEW_ORDER', 'SET_CHANGE_ORDER']),
    getIndex() {
      /**
       * VueNestable 的每個 item 需要 unique key，
       * 但資料庫中題組與題目的 id 會有重複的可能，所以不能直接以 id 來當 unique key
       * 於是讓題組在新增時多一個 group_id 欄位，來當做 unique key，作為跟題目的分別
       */

      let sort = 1;

      const copyOne = JSON.parse(JSON.stringify(this.getServerQuestionQueue));
      copyOne.forEach((item, index) => {
        if (item.question_type === 2) {
          item['key'] = item.group_id;
          item['index'] = [index];
          item['class'] = ['special-group'];
          item['questionnaire_id'] = parseInt(this.$route.query.id);
          item.question_array.forEach((ques, subIndex) => {
            ques['class'] = ['question-item'];
            ques['questionnaire_id'] = parseInt(this.$route.query.id);
            ques['question_type'] = 1;
            ques['sort'] = sort;
            ques['key'] = ques.id;
            ques['index'] = [index, subIndex];
            sort++;
          });
          index++;
        } else {
          item['class'] = ['question-item'];
          item['questionnaire_id'] = parseInt(this.$route.query.id);
          item['sort'] = sort;
          item['key'] = item.id;
          item['index'] = [index];
          sort++;
          index++;
        }
      });

      this.nestableItems = copyOne;
      this.SET_NEW_ORDER(this.nestableItems);
      // console.log('sever list', this.getServerQuestionQueue);
      // console.log('new list', this.getTempQuestionQueue);
    },
    handleChange(value, { items, pathTo }) {
      // 用來作為更動題組內問題時的識別 key
      // console.log('value:', value, 'items:', items, 'pathTo', pathTo);
      if (!pathTo) return;
      if (Object.values(value.index).toString() === pathTo.toString()) return;

      if (value.question_type === 2) {
        this.SET_ORDER(this.nestableItems);
        this.SET_CHANGE_ORDER(true);
        return false;
      }
      const isChild = value.index.length > 1;
      const isDragIn = pathTo?.length > 1;
      const groupIndex = pathTo[0];
      const childIndex = pathTo[1];
      const pathFrom = value.index;
      const group = this.nestableItems[groupIndex];
      const pathFromGroup = this.nestableItems[pathFrom[0]];

      //檢查題組有沒有超過5題
      const isOverFive = () => {
        if (group.question_array?.length === 6) {
          //還原移除
          group.question_array?.splice(childIndex, 1);
          //還原新增
          if (isChild) {
            pathFromGroup.question_array?.splice(pathFrom[1], 0, value);
          } else {
            this.nestableItems.splice(pathFrom[0], 0, value);
          }
          this.$alert('題組內最多5個題目', 'ERROR!', {
            type: 'error',
            confirmButtonText: '確定',
          }).catch((e) => e);
          return this.nestableItems;
        } else {
          return false;
        }
      };

      if (isDragIn) {
        //檢查能不能拖曳
        const recover = isOverFive();
        if (recover) {
          this.SET_ORDER(recover);
          return false;
        }
        group.changeOrder = true;
        //排除同個題組內的題目拖曳
        if (value.groupId !== group.group_id) {
          value.groupId = group.group_id;
          value.changeOrder = true;
        }
        //題組內題目拖曳到另一個題組
        if (isChild) {
          pathFromGroup.changeOrder = true;
        }
      } else {
        //題目拖出題組
        if (isChild) {
          value.groupId = '';
          value.changeOrder = true;
          pathFromGroup.changeOrder = true;
        }
      }
      this.SET_ORDER(this.nestableItems);
      this.SET_CHANGE_ORDER(true);
    },
    beforeMove({ dragItem, pathFrom, pathTo }) {
      // console.log('dragItem', dragItem, 'pathFrom', pathFrom, 'pathTo', pathTo);
      // 防止題組被拖進題組內
      if (pathTo.length > 1 && dragItem.question_type === 2) return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.special-group {
  border-top: none !important;
  background-color: #f3f3f3;
  margin: 10px 0;
  .el-table th,
  .el-table,
  .el-table tr {
    background-color: #f3f3f3;
  }

  .el-table th.is-leaf,
  .el-table td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.089);
  }

  .el-table thead {
    color: inherit;
    font-weight: 500;
  }
  .el-table::before {
    height: 0;
  }
}
/deep/.question-item {
  transition: 0.4s;
  &:hover {
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
  }
}

/deep/.drag-wrapper {
  display: grid;
  grid-template-columns: auto 40px;
}

/deep/.drag-wrapper-rtl {
  display: grid;
  grid-template-columns: 40px auto;
}

.card-content-header {
  display: grid;
  grid-template-columns: 1fr 2fr 4fr 2fr 3fr;
  align-items: center;
  line-break: anywhere;
}

/deep/.table-header {
  font-weight: bold;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 10px;
}

/*
* Style for nestable
*/

/deep/ol {
  padding: 0;
  margin: 0;
}
/deep/.nestable,
.nestable-group {
  position: relative;
  min-height: 560px;
}
/deep/.nestable-rtl {
  direction: rtl;
}
/deep/.nestable .nestable-list {
  margin: 0;
  list-style-type: none;
}

/deep/.nestable > .nestable-list {
  padding: 0;
}
/deep/.nestable-item {
  position: relative;
}
/deep/.nestable-item.is-dragging .nestable-list {
  pointer-events: none;
}
/deep/.nestable-item.is-dragging * {
  opacity: 0;
  filter: alpha(opacity=0);
}
/deep/.nestable-item.is-dragging:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1c63aa1a;
}

/deep/.nestable-drag-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
}

/deep/.nestable-drag-layer > .nestable-list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  background-color: #1c63aa1a;
}

/deep/.nestable [draggable='true'] {
  cursor: move;
}
/deep/.nestable-handle {
  display: inline;
  text-align: center;
  padding: 15px 0;
}

/deep/.nestable-item:not(.special-group) + .nestable-item {
  border-top: 1px solid #e2e2e2;
}
</style>
