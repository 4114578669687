<template>
  <div class="main">
    <div class="header">
      <span style="font-size: 20px; color: #2b2b2b">
        問卷標題：{{ getQuestionnaireTitle || '尚未設定' }}
      </span>
    </div>
    <el-divider></el-divider>
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <div>
        <div style="margin: 30px 0 10px; font-size: 18px">
          問卷刊登日期
          <el-popover
            placement="right"
            :visible-arrow="false"
            trigger="hover"
            popper-class="popoverBg"
          >
            <img
              slot="reference"
              src="../../assets/image/icon-info.png"
              width="22"
              style="transform: translateY(5px)"
            />
            <div>
              * 開始日期當天10:00刊登問卷。
              <br />
              * 結束日期當天10:00下架問卷。
              <br />
              * 總時間範圍不得超過90天(包含延長問卷時間)。
              <br />
              * 因問卷審核需作業，問卷開始日最早為7個工作天後。
            </div>
          </el-popover>
        </div>
        <el-form-item prop="valid_date">
          <el-date-picker
            v-model="form.valid_date"
            type="daterange"
            start-placeholder="刊登日期-開始時間"
            end-placeholder="刊登日期-結束時間"
            format="yyyy/MM/dd"
            value-format="yyyy/MM/dd"
            @blur="dateChange"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
      </div>

      <div>
        <div style="margin: 30px 0 10px; font-size: 18px">
          推播內容設定
          <el-popover
            placement="right"
            :visible-arrow="false"
            trigger="hover"
            popper-class="popoverBg"
          >
            <img
              slot="reference"
              src="../../assets/image/icon-info.png"
              width="22"
              style="transform: translateY(5px)"
            />
            <div>* 於問卷開始日當天10:00開始推播。</div>
          </el-popover>
        </div>
        <push-notification-block
          :choosed.sync="choosed"
          :title="getQuestionnaireTitle"
          :version="version"
        ></push-notification-block>
      </div>

      <el-form-item>
        <div>
          提醒您，送出申請前需檢查以下步驟是否完成：
          <ol style="padding-left: 16px; margin: 0">
            <li>
              請檢查您
              <el-link
                style="transform: translateY(-2px)"
                type="primary"
                :underline="false"
                @click="openPoint"
              >
                儲存的點數
              </el-link>
              是否足額發佈本份問卷。
            </li>
            <li>
              <el-link
                style="transform: translateY(-1.5px)"
                type="info"
                :underline="false"
                @click="toggleActiveTab('1')"
              >
                問卷資訊
              </el-link>
              及
              <el-link
                style="transform: translateY(-1.5px)"
                type="info"
                :underline="false"
                @click="toggleActiveTab('2')"
              >
                題目設定
              </el-link>
              項目是否皆已填寫完畢。
            </li>
          </ol>
        </div>
      </el-form-item>

      <p style="font-size: 14px">
        以上檢查完畢送出申請後，系統將預扣預估費用點數，並進入待審核狀態，若通過審核將依排程時間自動發佈，問卷結束後將於3個工作日內完成點數返還作業。
      </p>

      <el-form-item style="text-align: center; margin-top: 70px">
        <el-button type="primary" @click="sendRequest">送出申請</el-button>
      </el-form-item>
    </el-form>

    <el-dialog title="我的點數" :visible.sync="dialogVisible" width="30%">
      <el-table :data="point">
        <el-table-column prop="name" label="類型" width="150"></el-table-column>
        <el-table-column prop="count" label="剩餘點數"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { submitQuestionnaire } from '@/api/questionnaire';
import PushNotificationBlock from '@/components/questionnaire/PushNotificationBlock';
import getNotificationFormat from '@/mixin/getNotificationFormat';
import validateQuestion from '@/mixin/validateQuestion';

export default {
  name: 'FinalRelease',
  mixins: [getNotificationFormat, validateQuestion],
  components: { PushNotificationBlock },
  data() {
    return {
      dialogVisible: false,
      choosed: 0,
      form: {
        valid_date: [],
      },
      rules: {
        valid_date: [
          { required: true, message: '請選擇有效時間', trigger: 'blur' },
        ],
      },
      selectData: '',
      specificDate: [],
      pickerOptions: {
        //設置禁用日期：開始日期必須從今天算起7個工作天/問卷有效時間範圍不可超過90天
        disabledDate: this.disabledDate,
        //選擇日期的回調
        onPick: (time) => {
          this.selectData = time.minDate.getTime();
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getQuestionnaire',
      'getQuestionnaireTitle',
      'getTempQuestionQueue',
      'getChangeOrder',
      'getUserPointData',
    ]),
    point() {
      let data = [
        { name: '一般點數', count: this.getUserPointData.point || 0 },
        {
          name: 'Target-Q點數',
          count: this.getUserPointData.targetQ_point || 0,
        },
      ];
      return data;
    },
  },
  created() {
    this.renderSpecificDate();
  },
  methods: {
    ...mapActions(['toggleActiveTab', 'doGetPoint']),
    dateChange() {
      this.form.available_start_datetime = this.form.valid_date[0];
      this.form.available_end_datetime = this.form.valid_date[1];
    },
    disabledDate(time) {
      const nowDay = new Date();
      //尚未選擇日期：僅設置起始日期從今天算起7個工作天
      if (this.selectData === '') {
        if (time.getTime() < nowDay.addDays(9)) {
          return true;
        }
      } else {
        //已選擇日期，設置起始日期從今天算起7個工作天 與 結束日期不可超過選擇日期的90天
        const selectDay = new Date(this.selectData);
        if (
          time.getTime() < nowDay.addDays(9) ||
          time.getTime() > selectDay.addDays(89)
        ) {
          return true;
        }
      }
      //單月25號設為禁選日
      for (let i = 0; i < this.specificDate.length; i++) {
        if (time.getTime() == new Date(this.specificDate[i]).getTime()) {
          return true;
        }
      }
    },
    renderSpecificDate() {
      const nowDay = new Date();
      let year = nowDay.getFullYear();
      let month = nowDay.getMonth() + 1;
      let day = nowDay.getDate();
      let date_arr = [];
      if (month % 2 !== 0 && day < 25) {
        date_arr.push(`${year}/${month}/25 00:00:00`);
      }
      for (let i = 0; i < 12; i++) {
        let nextMonth = month + 1 > 12 ? 1 : month + 1;
        if (nextMonth < month) year++;
        if (nextMonth === 1 || nextMonth % 2 !== 0) {
          date_arr.push(`${year}/${nextMonth}/25 00:00:00`);
        }
        month = nextMonth;
      }
      this.specificDate = date_arr;
    },
    async openPoint() {
      await this.doGetPoint();
      this.dialogVisible = true;
    },
    async sendRequest() {
      const valid = await this.$refs['form'].validate().catch((e) => e);
      const setValid = this.$_validateSetting([
        'checkInfo',
        'checkQuestion',
        'checkGroupQuestion',
        'checkSaveSort',
      ]);
      if (!valid || !setValid) return false;
      let data = {
        questionnaire_id: parseInt(this.$route.query.id),
        notification_title: this.version[this.choosed].title,
        notification_content: this.version[this.choosed].content,
        available_start_datetime:
          this.form.available_start_datetime + ' 10:00:00',
        available_end_datetime: this.form.available_end_datetime + ' 10:00:00',
      };
      // console.log(data);
      await submitQuestionnaire(data)
        .then(() => {
          this.$message.success('已成功送出問卷申請，問卷審核需7個工作日');
          this.$router.push('/questionnaire-list');
        })
        .catch((error) => {
          this.$alert(error.message, 'ERROR!', {
            type: 'error',
            confirmButtonText: '確定',
          })
            .then(() => {
              this.$router.push('/user-profile');
            })
            .catch((e) => e);
        });
    },
  },
};
</script>
