import request from '@/utils/request';
const BASE_URL = '/targetQ/cdp/field';

//取得品牌習慣
export function getBrandList() {
  return request({
    method: 'get',
    url: `${BASE_URL}/getBrandNameList`,
  });
}

//取得消費通路
export function getChannelList() {
  return request({
    method: 'get',
    url: `${BASE_URL}/getChannelList`,
  });
}

//取得個性化標籤
export function getFeatureList() {
  return request({
    method: 'get',
    url: `${BASE_URL}/getFeatureList`,
  });
}

//取得消費品項
export function getItemList(level, tagId) {
  return request({
    method: 'get',
    url: `${BASE_URL}/getItemTagLevelWithLevelIdAndTagId?level_id=${level}&tag_id=${tagId}`,
  });
}

//關鍵字取得消費品項
export function getItemKeyword(keyword) {
  return request({
    method: 'get',
    url: `${BASE_URL}/getItemTagLevelWithKeyword?keyword=${keyword}`,
  });
}

//品項ruleId取得消費品項
export function getItemByRuleId(ruleId) {
  return request({
    method: 'get',
    url: `${BASE_URL}/getItemTagLevelWithRuleId?rule_id=${ruleId}`,
  });
}

//篩選受眾
export function doQuestionnaireFilter(data) {
  return request({
    method: 'post',
    url: `/targetQ/cdp/doQuestionnaireFilter`,
    data: data,
  });
}

//取得總人數
export function getTotalUserCount() {
  return request({
    method: 'get',
    url: `/targetQ/cdp/getTotalUserCount`,
  });
}

//取得生活區域-區域
export function getAreaRegionList() {
  return request({
    method: 'get',
    url: `${BASE_URL}/getAreaGroupList`,
  });
}

//取得生活區域-縣市
export function getAreaCityList(id) {
  return request({
    method: 'get',
    url: `${BASE_URL}/getAreaLevelByGroupId?group_id=${id}`,
  });
}

//取得生活區域-區
export function getAreaDistrictList(id) {
  return request({
    method: 'get',
    url: `${BASE_URL}/getAreaLevelListByParentId?parent_id=${id}`,
  });
}
