<template>
  <el-dialog
    v-if="dialogVisible"
    :visible="dialogVisible"
    @close="handleClose"
    width="700px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div slot="title">
      <span style="font-size: 18px">
        {{ dialogAction === 'create' ? '新增' : '編輯' }} - {{ dialogTitle }}
      </span>
    </div>
    <el-form
      :model="questionInfo"
      ref="ruleForm"
      label-position="left"
      label-width="100px"
      :rules="rules"
      :status-icon="true"
      :hide-required-asterisk="true"
    >
      <el-form-item label="題目" prop="title">
        <el-input
          v-model="questionInfo.title"
          placeholder="請輸入問題內容，字數限制120字以內"
        ></el-input>
      </el-form-item>

      <!-- 複選題 -->
      <el-form-item
        v-if="typeId === 3 || typeId === 13"
        label="最大選擇數量(0代表無限制)"
      >
        <el-input-number
          v-model="questionInfo.max_select"
          :min="0"
        ></el-input-number>
      </el-form-item>

      <!-- 是否必填 -->
      <el-form-item label="必填">
        <el-switch v-model="questionInfo.is_required"></el-switch>
      </el-form-item>

      <!-- 影片題 -->
      <el-form-item v-if="typeId === 9" label="影片網址" prop="video_link">
        <el-input
          v-model="questionInfo.video_link"
          placeholder="請輸入youtube網址連結"
        ></el-input>
      </el-form-item>

      <!-- 圖片輔助說明（單選、複選、下拉） -->
      <el-form-item v-if="withImage" label="圖片">
        <UploadImage :image.sync="questionInfo.image"></UploadImage>
      </el-form-item>

      <el-divider></el-divider>
      <!-- 選項備註說明 -->
      <div>
        <ul
          style="
            color: #909399;
            padding-left: 20px;
            margin-bottom: 20px;
            list-style-type: square;
          "
        >
          <li v-for="(item, index) in remark" :key="index">{{ item }}</li>
        </ul>
        <el-radio-group
          v-model="chooseLikertOption"
          v-if="typeId === 2 || typeId === 13"
          style="margin-bottom: 20px"
          @change="handleChangeOption"
        >
          <el-radio :label="5">5個</el-radio>
          <el-radio :label="7">7個</el-radio>
        </el-radio-group>
      </div>
      <!-- 選項區 -->
      <div v-if="error.error_text">
        <p class="error-text">
          <i class="el-icon-circle-close"></i>
          {{ error.error_text }}
        </p>
      </div>
      <el-row :gutter="24" v-for="(option, index) in options" :key="index">
        <el-col :span="11">
          <el-form-item
            label="選項"
            label-width="50px"
            :class="option.inputError ? 'error-input' : ''"
          >
            <el-input
              v-model="option.title"
              placeholder="請輸入..."
              @change="validateOptionCount(option.title, index)"
            ></el-input>
            <small v-if="option.inputError">
              <i class="el-icon-circle-close"></i>
              {{ option.inputError }}
            </small>
          </el-form-item>
        </el-col>
        <!-- 跳題：在複選題不顯示 -->
        <el-col :span="10" v-show="typeId !== 3 && typeId !== 13">
          <el-form-item label="跳題" label-width="50px">
            <el-select
              :value="checkFlat(index, option.jump_to)"
              @change="updateFalt(index, $event)"
              :disabled="dialogAction !== 'edit' || getFlatQuestion.length < 1"
              :class="option.jump_to === '-2' ? 'red-font' : ''"
            >
              <el-option label="無" :value="'-1'"></el-option>
              <el-option
                v-for="question in getFlatQuestion"
                :key="question.key"
                :label="question.value || '尚未設定名稱的題組'"
                :value="question.key"
                style="max-width: 200px"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 刪除選項：在直式題不顯示 -->
        <el-col :span="2">
          <el-button
            v-show="typeId !== 2 && typeId !== 13 && options.length > 2"
            type="info"
            icon="el-icon-delete"
            @click="handleDeleteOption(index)"
          ></el-button>
        </el-col>
        <!-- 圖片題專屬 -->
        <el-col v-if="imagePerOption" style="margin-bottom: 30px">
          <el-col>
            <UploadImage :image.sync="option.image"></UploadImage>
          </el-col>
        </el-col>
      </el-row>
      <!-- 新增選項：在直式題不顯示 -->
      <el-button
        v-if="typeId !== 2 && typeId !== 13"
        type="text"
        @click="handleCreateOption"
      >
        新增選項
      </el-button>

      <div style="display: flex; justify-content: flex-end">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">確定送出</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import UploadImage from '@/components/common/UploadImage';

export default {
  name: 'GeneralType',
  props: {
    typeId: { type: Number, default: -1 },
    withImage: { type: Boolean, default: false },
    imagePerOption: { type: Boolean, default: false },
    remark: { type: Array },
    dialogAction: { type: String },
    dialogVisible: { type: Boolean },
    questionInfo: { type: Object },
    dialogTitle: { type: String },
  },
  components: { UploadImage },
  data() {
    //驗證影片連結
    let validateVideo = (rule, value, callback) => {
      const regex = /^(http:\/\/|https:\/\/|)\w{0,}(.youtube.com|youtu.be)\//;

      if (!regex.test(value)) {
        callback(new Error('請輸入正確的youtube影片連結'));
      } else {
        callback();
      }
    };
    return {
      rules: {
        title: [
          { required: true, message: '請輸入問題內容', trigger: 'blur' },
          { max: 120, message: '字數不可超過120字', trigger: 'change' },
        ],
        video_link: [
          { required: true, message: '請輸入youtube影片連結', trigger: 'blur' },
          { validator: validateVideo, trigger: 'blur' },
        ],
      },
      chooseLikertOption: 5,
      initOptionCount: null,
      wordLimit: null,
      optionLimit: null,
      options: [],
      initOptionInfo: {
        id: 0,
        title: '',
        description: '',
        image: '',
        is_answer: false,
        is_require: false,
        is_jump: false,
        jump_to: '-1',
      },
      error: {
        error_text: '',
        hasError: false,
      },
    };
  },
  computed: {
    ...mapGetters(['getTempQuestionQueue']),
    //跳題
    getFlatQuestion() {
      return this.questionInfo.can_jump_question_list || [];
    },
  },
  watch: {
    questionInfo: {
      deep: true,
      //處理複選選項最大值必須小於選項數量-1
      handler() {
        if (this.questionInfo.max_select === this.options.length)
          this.questionInfo.max_select = 0;
      },
    },
    dialogVisible: {
      handler() {
        if (this.dialogVisible) {
          this.fetchOption();
          // console.log('question', this.questionInfo);
        }
      },
      immediate: true,
    },
  },
  methods: {
    //處理選項的數量和內容
    fetchOption() {
      //不同題型的預設選項數量/限制與字數限制
      switch (this.typeId) {
        case 2:
        case 13:
          this.initOptionCount = this.chooseLikertOption;
          this.optionLimit = this.chooseLikertOption;
          this.wordLimit = 6;
          break;
        case 4:
          this.initOptionCount = 2;
          this.optionLimit = 2;
          this.wordLimit = 13;
          break;
        case 8:
          this.initOptionCount = 4;
          this.optionLimit = 2;
          this.wordLimit = 13;
          break;
        default:
          this.initOptionCount = 4;
          this.optionLimit = 2;
          this.wordLimit = 200;
          break;
      }

      switch (this.dialogAction) {
        case 'edit':
          this.options = JSON.parse(
            JSON.stringify(this.questionInfo.option_array)
          );
          this.chooseLikertOption = this.options.length;
          break;
        case 'create':
          this.initOptions();
          break;
      }
    },
    //初始化選項
    initOptions() {
      this.options = [];
      for (let i = 0; i < this.initOptionCount; i++) {
        this.options.push(JSON.parse(JSON.stringify(this.initOptionInfo)));
      }
    },
    //新增選項
    handleCreateOption() {
      this.initOptionCount++;
      this.options.push(JSON.parse(JSON.stringify(this.initOptionInfo)));
    },
    //刪除選項
    handleDeleteOption(index) {
      this.options.splice(index, 1);
    },
    //直式題切換選項
    handleChangeOption() {
      this.initOptionCount = this.chooseLikertOption;
      this.optionLimit = this.chooseLikertOption;
      this.error = {
        error_text: '',
        hasError: false,
      };
      if (this.chooseLikertOption === 5) {
        this.options.pop();
        this.options.pop();
      } else {
        this.options.push(JSON.parse(JSON.stringify(this.initOptionInfo)));
        this.options.push(JSON.parse(JSON.stringify(this.initOptionInfo)));
      }
    },
    //檢查跳題
    checkFlat(index, value) {
      if (value !== '-1') {
        if (
          this.getFlatQuestion
            .map((e) => {
              return e.key;
            })
            .includes(value)
        ) {
          return value;
        } else {
          this.options[index].jump_to = '-2';
          this.options[index].is_jump = false;
          return '☹ 跳題錯誤，請重選！';
        }
      } else {
        return value;
      }
    },
    //更新跳題選項
    updateFalt(index, newValue) {
      this.options[index].jump_to = newValue;
      if (newValue === '-1') {
        this.options[index].is_jump = false;
      } else {
        this.options[index].is_jump = true;
      }
    },
    //關閉彈窗-取消
    handleClose() {
      this.$emit('update:showQuestionDialog', false);
      this.chooseLikertOption = 5;
      this.error = {
        error_text: '',
        hasError: false,
      };
      this.$refs['ruleForm'].clearValidate();
    },
    //送出題目-確認送出
    async handleSubmit() {
      //先把沒有填寫的選項剔除
      let real_option = [];
      switch (this.typeId) {
        case 4:
          real_option = this.options.filter((el) => {
            return el.image !== '' || el.title !== '';
          });
          break;
        default:
          real_option = this.options.filter((el) => {
            return el.title !== '';
          });
          break;
      }
      const valid = await this.$refs['ruleForm'].validate().catch((e) => e);
      this.validateOption(real_option);
      if (!valid || this.error.hasError) return false;
      // console.log(real_option);
      this.$emit('handleUpdateQuestion', real_option);
      this.$emit('update:showQuestionDialog', false);
      // 回復初始值
      this.options = [];
      this.chooseLikertOption = 5;
      this.error = {
        error_text: '',
        hasError: false,
      };
    },
    //檢查選項字數
    validateOptionCount(val, index) {
      if (val.length > this.wordLimit) {
        const copyOne = JSON.parse(JSON.stringify(this.options));
        copyOne[index].inputError = `選項內容字數須小於${this.wordLimit}個字`;
        this.options = copyOne;
      } else {
        const copyOne = JSON.parse(JSON.stringify(this.options));
        delete copyOne[index].inputError;
        this.options = copyOne;
      }
    },
    //檢查選項數量
    validateOption(real_option) {
      this.error = {
        error_text: '',
        hasError: false,
      };
      if (real_option.length < this.optionLimit) {
        switch (this.typeId) {
          case 4:
            this.error.error_text = `須設定至少${this.optionLimit}個選項的圖片`;
            break;
          default:
            this.error.error_text = `須填寫至少${this.optionLimit}個選項`;
            break;
        }
        this.error.hasError = true;
        return false;
      } else {
        if (this.typeId === 4) {
          this.error.hasError = real_option.some((el) => {
            return !el.image;
          });
          if (this.error.hasError) {
            this.error.error_text = `選項須設定圖片`;
            this.$message.error(`選項須設定圖片`);
            return false;
          }
        }
      }
      this.error.hasError = real_option.some((el) => {
        return el.inputError?.length > 0;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.red-font {
  /deep/.el-input__inner {
    color: #f56c6c !important;
  }
}
.error-text {
  font-size: 13px;
  color: #f56c6c;
}
.error-input {
  color: #f56c6c;
  /deep/.el-input__inner {
    border: 1px solid #f56c6c !important;
  }
  /deep/.el-form-item__content {
    line-height: 1rem;
  }
}
</style>
