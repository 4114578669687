<template>
  <section>
    <div v-for="(i, index) in stepsList" :key="i.title">
      <div style="position: relative">
        <div class="steps active" style="height: 100%; width: 32px">
          <div class="circle">{{ index + 1 }}</div>
          <div class="divider-vertical">
            <span>{{ i.title }}</span>
          </div>
        </div>

        <div style="margin-left: 70px">
          <div v-show="index + 1 !== 1" class="divider-hr"></div>
          <div style="margin-top: 30px">
            <slot :name="`desc${index + 1}`"></slot>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'VSteps',
  props: {
    stepsList: {
      required: true,
    },
    activeSteps: {
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;

  .circle {
    border-radius: 50%;
    border: solid 2px #bfbfbf;
    background-color: #ffffff;
    width: 32px;
    height: 32px;
    color: #bfbfbf;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divider-vertical {
    height: 100%;
    width: 2px;
    position: relative;
    background-color: #bfbfbf;

    span {
      position: absolute;
      top: 50%;
      left: -8px;
      transform: translateY(-50%);
      background-color: #fff;
      padding: 15px 0;
      color: #bfbfbf;
      font-size: 20px;
      font-family: PingFangTC-Medium;
    }
  }
}

.active {
  .circle {
    border-color: #1c63aa;
    background-color: #1c63aa;
    color: #ffffff;
  }
  .divider-vertical {
    background-color: #1c63aa;

    span {
      color: #1c63aa;
    }
  }
}

.divider-hr {
  display: block;
  width: 90%;
  height: 1px;
  margin: 0 auto;
  background-color: #bfbfbf;
  position: relative;
  top: 18px;
}
</style>
