<template>
  <div class="main">
    <section
      style="
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        color: #646464;
      "
    >
      <div>費用說明</div>
      <div>
        <ul class="list-number">
          <li>
            每份問卷回收服務費為{{
              typeOne.recover_fee
            }}點，例：回收1000份問卷，則回收費用為{{
              typeOne.recover_fee * 1000
            }}點。
          </li>
          <li>推播服務費每則計費皆為{{ typeOne.push_fee }}點。</li>
          <li>
            填答回饋點數：獎勵每份問卷填答完成者最低{{
              typeOne.min_feedback_point
            }}點，最高1000點之回饋點數，以整數1點為單位，於點數區間內由問卷主自行設定。填答者會以雲寶點數形式獲得，1點即為1雲寶點數。
          </li>
          <li>
            系統將預扣預估費用點數，待問卷結束後將自動計算是否有需返還點數，並於3個工作天內完成點數返還作業，首次推播為一次性費用，問卷一旦進入刊登中即開始推播，不列入扣點返還範圍。
          </li>
        </ul>
        <!-- <div>
          <span style="padding-left: 20px">註：</span>
          <ul class="list-letter">
            <li>
              合作之學術單位，優惠免收「問卷回收服務費」，「推播服務費」優惠每則{{
                typeTwo.push_fee
              }}點。
            </li>
            <li>
              合作之學術單位「填答回饋點數」優惠最低為{{
                typeTwo.min_feedback_point
              }}點。
            </li>
          </ul>
        </div> -->
      </div>
    </section>

    <div style="margin-top: 48px">
      <el-divider content-position="left">
        <label class="switch">
          <input
            type="checkbox"
            v-model="form.cost_assessment_type"
            @change="handleAssessmentType"
          />
          <div class="slider round">
            <span id="false">依預算評估份數</span>
            <span id="true">依份數評估費用</span>
          </div>
        </label>
      </el-divider>
    </div>

    <el-row type="flex">
      <el-col :span="16">
        <el-form
          ref="form"
          :model="form"
          label-position="top"
          :rules="rules"
          @validate="validateForm"
        >
          <el-form-item
            v-show="!form.cost_assessment_type"
            label="預算上限"
            prop="budget_limit"
          >
            <div class="flex-row">
              <el-input
                type="number"
                :min="0"
                v-model="form.budget_limit"
                placeholder="請輸入預算上限"
              ></el-input>
              <span>點</span>
            </div>
            <div class="input-content">
              輸入預算上限、填答回饋點數後，將自動產生回收份數上限與首次推播人數。
            </div>
          </el-form-item>

          <el-form-item
            v-show="form.cost_assessment_type"
            label="回收份數上限"
            prop="recover_copy_limit"
          >
            <div class="flex-row">
              <el-input
                type="number"
                :min="0"
                v-model="form.recover_copy_limit"
                placeholder="請輸入回收份數上限"
              ></el-input>
              <span>人</span>
            </div>
            <div class="input-content">
              輸入回收份數上限、填答回饋點數後，將自動產生首次推播人數與預估費用。
            </div>
          </el-form-item>

          <el-form-item label="填答回饋點數" prop="feedback_point">
            <div class="flex-row">
              <el-input
                type="number"
                :min="0"
                :max="1000"
                v-model="form.feedback_point"
                :placeholder="`最低回饋點數為${minFeedback}點/人`"
              ></el-input>
              <span>點/人</span>
            </div>
            <div class="input-content">
              <ol style="padding-left: 18px">
                <li>
                  填答回饋點數是每份問卷填答者完成後所獲得的獎勵點數。須介於{{
                    minFeedback
                  }}-1000點/人之間。
                </li>
                <li>回饋點數越高填答率越高，反之亦然。</li>
              </ol>
            </div>
            <div
              v-show="form.over_budget"
              style="
                color: #f56c6c;
                font-size: 12px;
                position: absolute;
                bottom: -50px;
              "
            >
              回饋點數須調整在預算內
            </div>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="8" style="padding: 20px 0">
        <section class="count">
          <div class="title">
            {{ form.cost_assessment_type ? '首次推播人數' : '回收份數上限' }}
          </div>
          <div v-show="form.feedback_point !== ''" class="subtitle">
            {{
              `填答回饋點數${form.feedback_point}點之填答率為${probability}％`
            }}
          </div>
          <div class="count-row">
            <div
              class="number-black"
              v-price-format="
                form.cost_assessment_type
                  ? form.deliver_count
                  : form.recover_copy_limit
              "
            ></div>
            <span>人</span>
          </div>
        </section>

        <!-- 依預算評估才出現 -->
        <div v-show="!form.cost_assessment_type">
          <el-divider></el-divider>
          <section class="count">
            <div class="title" style="color: #75553a">首次推播人數</div>
            <div class="count-row">
              <div
                class="number-black"
                v-price-format="form.deliver_count"
              ></div>
              <span>人</span>
            </div>
          </section>
        </div>
        <!------------------->
      </el-col>
    </el-row>

    <el-divider></el-divider>

    <section>
      <el-row>
        <el-col :span="16">
          <div :class="['info', hideClass]">
            <div>專屬優惠</div>
            <ol style="margin: 0">
              <li>免除每份問卷回收服務費。</li>
              <li>減免每份問卷推播服務費至{{ typeTwo.push_fee }}點/則</li>
            </ol>
          </div>
        </el-col>
        <el-col :span="8">
          <section class="count-row">
            <div class="title">預估費用</div>
            <div class="number" v-price-format="form.estimated_cost"></div>
            <span>點</span>
          </section>
        </el-col>
      </el-row>

      <el-row style="margin-top: 20px">
        <el-col :span="24">
          <div style="margin: 0 24px 10px 0">計算公式</div>
          <el-table :data="[form]">
            <el-table-column
              v-for="(col, index) in columns"
              :key="index"
              :prop="col.id"
              :label="col.label"
              :width="col.width"
              align="center"
            >
              <template scope="{row}">
                <div v-if="col.id === 'recover_fee'">
                  {{ recoverFee }}
                </div>
                <div v-else-if="col.id === 'push_fee'">
                  {{ pushFee }}
                </div>
                <div v-else-if="col.id === 'pro_filter_fee'">
                  {{ proFilterFee }}
                </div>
                <div v-else-if="col.id === 'pro_filter'">
                  {{ pro_filter }}
                </div>
                <div v-else>{{ row[col.id] }}</div>
              </template>
            </el-table-column>
            <!-- 預估費用有兩種可能 -->
            <el-table-column
              v-if="!form.cost_assessment_type"
              label="預估費用 ≤ 預算上限"
              width="155px"
              align="center"
            >
              {{ form.estimated_cost || 0 }} ≤ {{ form.budget_limit }}
            </el-table-column>
            <el-table-column
              v-else
              label="預估費用"
              width="80px"
              align="center"
            >
              {{ form.estimated_cost }}
            </el-table-column>
            <!----------------------->
          </el-table>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import getFee from '@/mixin/getFee';
export default {
  name: 'budget',
  mixins: [getFee],
  props: {
    step2: Object,
    pro_filter: Number,
  },
  data() {
    let checkbudgetLimit = (rule, value, callback) => {
      const regex = /^[1-9][0-9]*$/;
      console.log(regex.test(value));
      if (!value) {
        callback(new Error('請輸入預算上限'));
        this.form.budget_limit = 0;
        this.form.recover_copy_limit = 0;
        this.form.deliver_count = 0;
        this.form.estimated_cost = 0;
      } else if (value < 0) {
        callback(new Error('不可為負數'));
        this.form.budget_limit = 0;
        this.form.recover_copy_limit = 0;
        this.form.deliver_count = 0;
        this.form.estimated_cost = 0;
      } else if (!regex.test(value)) {
        callback(new Error('請輸入正確的數字'));
        this.form.budget_limit = 0;
        this.form.recover_copy_limit = 0;
        this.form.deliver_count = 0;
        this.form.estimated_cost = 0;
      } else {
        callback();
      }
    };
    let checkRecoverLimit = (rule, value, callback) => {
      const regex = /^[1-9][0-9]*$/;
      if (!value) {
        callback(new Error('請輸入回收份數上限'));
        this.form.recover_copy_limit = 0;
        this.form.deliver_count = 0;
        this.form.estimated_cost = 0;
      } else if (value < 0) {
        callback(new Error('不可為負數'));
        this.form.recover_copy_limit = 0;
        this.form.deliver_count = 0;
        this.form.estimated_cost = 0;
      } else if (!regex.test(value)) {
        callback(new Error('請輸入正確的數字'));
        this.form.recover_copy_limit = 0;
        this.form.deliver_count = 0;
        this.form.estimated_cost = 0;
      } else {
        callback();
      }
    };
    let checkFeedbackPoint = (rule, value, callback) => {
      const regex = /^[1-9][0-9]*$/;
      const min = this.userType.min_feedback_point;
      if (value < min) {
        callback(new Error(`不可低於${min}點`));
        this.form.feedback_point = min;
      } else if (value > 1000) {
        callback(new Error('不可高於1000點'));
        this.form.feedback_point = 1000;
      } else if (!regex.test(value)) {
        callback(new Error('請輸入正確的數字'));
        this.form.feedback_point = min;
      } else {
        callback();
      }
    };
    return {
      rules: {
        budget_limit: [
          { required: true, validator: checkbudgetLimit, trigger: 'blur' },
        ],
        feedback_point: [
          { required: true, validator: checkFeedbackPoint, trigger: 'blur' },
        ],
        recover_copy_limit: [
          { required: true, validator: checkRecoverLimit, trigger: 'blur' },
        ],
      },
      probability: 0,
      validate_sum: 0,
      columns: [
        {
          id: 'feedback_point',
          label: '[ 填答回饋點數',
          width: '115px',
        },
        { label: '+', width: '30px' },
        { id: 'recover_fee', label: '回收服務費', width: '90px' },
        { label: '+', width: '30px' },
        { id: 'pro_filter', label: 'pro進階篩選(個)', width: '125px' },
        { label: 'x', width: '30px' },
        {
          id: 'pro_filter_fee',
          label: 'pro進階服務費 ]',
          width: '125px',
        },
        { label: 'x', width: '30px' },
        { id: 'recover_copy_limit', label: '回收份數上限', width: '110px' },
        { label: '+', width: '30px' },
        { id: 'deliver_count', label: '首次推播人數', width: '110px' },
        { label: 'x', width: '30px' },
        { id: 'push_fee', label: '推播服務費', width: '90px' },
        { label: '=', width: '30px' },
      ],
    };
  },
  computed: {
    ...mapGetters(['formColumnSpan']),
    form: {
      get() {
        return this.step2;
      },
      set(val) {
        //this.$emit('update:step2', val);
      },
    },
    hideClass() {
      return Number(sessionStorage.getItem('userType')) ? 'hide' : ''; //2022.08改全type隱藏
    },
  },
  watch: {
    'form.budget_limit'() {
      if (this.form.budget_limit > 0 && this.form.feedback_point > 0) {
        this.calculate();
      }
    },
    'form.feedback_point'(val) {
      let value = parseInt(val);

      if (this.form.cost_assessment_type) {
        if (
          this.form.recover_copy_limit > 0 &&
          this.form.feedback_point > 0 &&
          value >= this.userType.min_feedback_point &&
          value <= 1000
        ) {
          this.calculate();
        }
      } else {
        if (
          this.form.budget_limit > 0 &&
          this.form.feedback_point > 0 &&
          value >= this.userType.min_feedback_point &&
          value <= 1000
        ) {
          this.calculate();
        }
      }
    },
    'form.recover_copy_limit'() {
      if (this.form.recover_copy_limit > 0 && this.form.feedback_point > 0) {
        this.calculate();
      }
    },
    'form.over_bidget'() {
      if (!this.form.over_budget && this.validate_sum === 2) {
        this.$emit('activeSteps', 3);
      } else {
        this.$emit('activeSteps', 2);
      }
    },
    validate_sum() {
      if (this.validate_sum > 2) {
        this.validate_sum = 2;
      }
      if (this.validate_sum === 2 && !this.form.over_budget) {
        this.$emit('activeSteps', 3);
      } else {
        this.$emit('activeSteps', 2);
      }
    },
    pro_filter() {
      this.calculate();
    },
  },
  methods: {
    handleAssessmentType() {
      let form = {
        deliver_count: 0,
        estimated_cost: 0,
        budget_limit: 0,
        feedback_point: 0,
        recover_copy_limit: 0,
        cost_assessment_type: this.form.cost_assessment_type,
        over_budget: false,
      };
      this.probability = 0;
      this.$emit('update:step2', form);
      this.$refs['form'].clearValidate();
    },
    calculate() {
      const question_charge = this.recoverFee;
      const send_charge = this.pushFee;
      const filter_charge = this.proFilterFee;

      let probability = this.form.feedback_point * 0.5;
      this.probability = probability >= 100 ? 100 : probability;

      //依預算評估的預估費用計算
      if (!this.form.cost_assessment_type) {
        let exp1 = this.form.feedback_point * 0.005; //填答率
        let exp2 =
          (parseInt(this.form.feedback_point) +
            question_charge +
            this.pro_filter * filter_charge) *
            exp1 +
          send_charge;
        let y = this.form.budget_limit / exp2; //首次推播人數
        let x = exp1 * y; // 回收份數上限
        this.form.recover_copy_limit = Math.floor(x);
        this.form.deliver_count = x > y ? Math.floor(x) : Math.floor(y);
        let estimated_cost =
          (parseInt(this.form.feedback_point) +
            question_charge +
            this.pro_filter * filter_charge) *
            this.form.recover_copy_limit +
          this.form.deliver_count * send_charge;

        this.form.estimated_cost = Math.ceil(estimated_cost);
        if (this.form.estimated_cost > this.form.budget_limit) {
          this.form.over_budget = true;
        } else {
          this.form.over_budget = false;
        }
      } else {
        //依份數評估的預估費用計算
        let y =
          this.form.recover_copy_limit / (this.form.feedback_point * 0.005); //首次推播人數
        this.form.deliver_count =
          this.form.feedback_point * 0.005 >= 1
            ? this.form.recover_copy_limit
            : Math.round(y);
        let estimated_cost =
          (parseInt(this.form.feedback_point) +
            question_charge +
            this.pro_filter * filter_charge) *
            this.form.recover_copy_limit +
          this.form.deliver_count * send_charge;
        this.form.estimated_cost = Math.ceil(estimated_cost);
        this.form.over_budget = false;
      }
    },
    validateForm(formName, valid) {
      if (!valid) {
        if (this.validate_sum !== 0) {
          this.validate_sum -= 1;
        }
      } else {
        this.validate_sum += 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  display: flex;
  flex-direction: row;
  color: #646464;
}
.info.hide {
  color: transparent;
}
</style>
