<template>
  <div class="main">
    <slot id="backToTop"></slot>
    <div class="header" style="margin-bottom: 20px">
      <div style="font-size: 20px; color: #4a4a4a">
        問卷標題：{{ getQuestionnaireTitle }}
      </div>
      <div>
        <div>
          <el-button
            type="primary"
            style="margin-right: 10px"
            @click="handleGroup('create')"
            icon="el-icon-plus"
          >
            新增題組
          </el-button>
          <el-dropdown
            trigger="click"
            @command="handleQuestionType($event, 'create')"
          >
            <el-button type="primary" icon="el-icon-plus">
              新增題目
              <i class="el-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="i in questionType"
                :key="i.id"
                :command="i"
              >
                {{ i.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <p
          style="
            font-size: 14px;
            color: #f56c6c;
            text-align: right;
            margin: 10px 0 0 0;
          "
        >
          *如有拖曳題目/題組，請再次檢查跳題設定
        </p>
      </div>
    </div>

    <!-- 題目列表 -->
    <question-table></question-table>

    <div style="width: fit-content; margin: 30px auto">
      <el-button
        plain
        @click="handlePreview"
        :disabled="getTempQuestionQueue.length < 1"
      >
        預覽
      </el-button>
      <el-button
        type="primary"
        @click="handleSaveQuestionSort"
        :disabled="!getChangeOrder || getTempQuestionQueue.length < 1"
      >
        儲存排序
      </el-button>
      <el-button
        type="primary"
        @click="toggleActiveTab('3')"
        :disabled="getChangeOrder || getTempQuestionQueue.length < 1"
      >
        設定發佈
      </el-button>
    </div>

    <!-- 新增題組外題目 -->
    <component
      :dialog-title="dialogQuestionType.name"
      :dialog-visible="showQuestionDialog"
      :is="dialogQuestionType.template"
      :type-id="dialogQuestionType.id"
      :with-image="dialogQuestionType.withImage"
      :image-per-option="dialogQuestionType.imagePerOption"
      :remark="dialogQuestionType.remark"
      :dialog-action="dialogAction"
      :question-info="questionInfo"
      @update:showQuestionDialog="showQuestionDialog = $event"
      @handleUpdateQuestion="handleUpdateQuestion($event, null)"
    ></component>

    <!-- 預覽問卷 -->
    <PreviewQuestions></PreviewQuestions>

    <!-- 新增/編輯題組 -->
    <EditGroup
      :showGroupDialog.sync="showGroupDialog"
      :groupInfo="groupInfo"
      :action="GroupDialogAction"
      @submitGroup="submitGroup($event, 'create')"
    ></EditGroup>
  </div>
</template>
<script>
import GeneralType from '@/components/questionType/GeneralType';
import TextareaType from '@/components/questionType/TextareaType';
import QuestionTable from '@/components/createQuestion/QuestionTable';
import PreviewQuestions from '@/components/questionnaireList/PreviewQuestions';
import EditGroup from '@/components/questionType/EditGroup';
import createQuestionTool from '@/mixin/createQuestionTool';
import validateQuestion from '@/mixin/validateQuestion';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'createQuestion',
  mixins: [createQuestionTool, validateQuestion],
  components: {
    GeneralType,
    TextareaType,
    QuestionTable,
    PreviewQuestions,
    EditGroup,
  },
  data() {
    return {
      previewQuestionnaire: false,
    };
  },
  computed: {
    ...mapGetters([
      'getTempQuestionQueue',
      'getQuestionnaireTitle',
      'getServerQuestionQueue',
      'getChangeOrder',
    ]),
  },
  methods: {
    ...mapActions(['saveAllChange', 'toggleActiveTab']),
    ...mapMutations(['SET_ORDER', 'SET_CHANGE_ORDER']),
    //預覽問卷
    async handlePreview() {
      const setValid = this.$_validateSetting([
        'checkQuestion',
        'checkGroupQuestion',
      ]);
      if (!setValid) return false;
      //判斷有沒有儲存排序
      if (this.getChangeOrder) {
        try {
          await this.saveQuestionSort();
        } catch (e) {
          return;
        }
      }
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, dialog: 'PreviewQuestions' },
      });
    },
    //儲存題目排序
    async handleSaveQuestionSort() {
      const setValid = this.$_validateSetting([
        'checkQuestion',
        'checkGroupQuestion',
      ]);
      if (!setValid) return false;
      // console.log('排序', this.getFormatSaveData());
      try {
        await this.saveQuestionSort();
        this.$message.success('儲存成功！請檢查跳題設定是否正確');
      } catch (e) {
        return;
      }
    },

    async saveQuestionSort() {
      try {
        await this.saveAllChange({
          id: parseInt(this.$route.query.id),
          question_array: this.getFormatSaveData(),
        });
        this.deleteChangeOrder();
        return Promise.resolve();
      } catch (e) {
        return Promise.reject();
      }
    },
    // 取得目前題目的排序
    getFormatSaveData() {
      const newSort = this.getTempQuestionQueue.map((item) => {
        let sub_question_array =
          item.question_type === 1 ? [] : item.question_array.map((e) => e.id);
        return { type: item.question_type, id: item.id, sub_question_array };
      });
      return newSort;
    },

    //移除ChangeOrder
    deleteChangeOrder() {
      const copyOne = JSON.parse(JSON.stringify(this.getServerQuestionQueue));
      copyOne.forEach((el) => {
        delete el.changeOrder;
        el.question_array?.forEach((el) => {
          delete el.changeOrder;
        });
      });
      this.SET_ORDER(copyOne);
      this.SET_CHANGE_ORDER(false);
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start !important;
}
</style>
