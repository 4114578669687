<template>
  <el-dialog
    v-if="dialogVisible"
    :title="dialogTitle"
    :visible="dialogVisible"
    @close="handleClose"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div slot="title">
      <span style="font-size: 18px">
        {{ dialogAction === 'create' ? '新增' : '編輯' }} - {{ dialogTitle }}
      </span>
      <ul
        style="
          font-size: 14px;
          color: #909399;
          list-style-type: none;
          padding-left: 0;
          margin: 10px 0 0 0;
        "
      >
        <li v-for="(item, index) in remark" :key="index">* {{ item }}</li>
      </ul>
    </div>
    <el-form
      :model="questionInfo"
      ref="ruleForm"
      label-position="left"
      label-width="70px"
      :rules="rules"
      :status-icon="true"
      :hide-required-asterisk="true"
      @submit.native.prevent
    >
      <el-form-item label="題目" prop="title">
        <el-input
          v-model="questionInfo.title"
          placeholder="請輸入問題內容，字數限制120字以內"
        ></el-input>
      </el-form-item>
      <el-form-item label="必填">
        <el-switch v-model="questionInfo.is_required"></el-switch>
      </el-form-item>
      <!-- 圖片輔助說明（簡答、評比） -->
      <el-form-item v-if="withImage" label="圖片">
        <UploadImage :image.sync="questionInfo.image"></UploadImage>
      </el-form-item>
      <div style="display: flex; justify-content: flex-end">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">確定送出</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
import UploadImage from '@/components/common/UploadImage';

export default {
  name: 'TextareaType',
  props: {
    typeId: { type: Number, default: -1 },
    withImage: { type: Boolean, default: false },
    imagePerOption: { type: Boolean, default: false },
    dialogAction: { type: String },
    questionInfo: { type: Object },
    dialogVisible: { type: Boolean },
    dialogTitle: { type: String },
    remark: { type: Array },
  },
  components: { UploadImage },
  data() {
    return {
      rules: {
        title: [
          { required: true, message: '請輸入問題內容', trigger: 'blur' },
          { max: 120, message: '字數不可超過120字', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    handleClose() {
      this.$emit('update:showQuestionDialog', false);
      this.$refs['ruleForm'].clearValidate();
    },
    async handleSubmit() {
      const valid = await this.$refs['ruleForm'].validate().catch((e) => e);
      if (!valid) return false;
      this.$emit('handleUpdateQuestion');
      this.$emit('update:showQuestionDialog', false);
    },
  },
};
</script>
