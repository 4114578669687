import { mapGetters, mapActions, mapMutations } from 'vuex';

const validateQuestion = {
  data() {
    return {
      check: {
        checkSaveForm: false,
        checkInfo: false,
        checkQuestion: false,
        checkGroupQuestion: false,
        checkSaveSort: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getQuestionnaire',
      'getTempQuestionQueue',
      'getChangeOrder',
      'getChangeInfo',
    ]),
  },
  methods: {
    $_validateSetting(checkList) {
      //確認要檢查哪些項目
      for (let item of Object.keys(this.check)) {
        this.check[item] = checkList.includes(item);
      }
      //檢查有沒有未儲存的問卷表單資訊
      if (this.check.checkSaveForm) {
        if (this.getChangeInfo) {
          this.$alert('尚未儲存更新的問卷資訊', '注意!', {
            type: 'warning',
            confirmButtonText: '確定',
          })
            .then(() => {
              this.toggleActiveTab('1');
            })
            .catch(() => {
              this.toggleActiveTab('1');
            });
          return false;
        }
      }
      //檢查問卷資訊的必填欄位
      if (this.check.checkInfo) {
        const isSetInfo =
          this.getQuestionnaire.recover_copy_limit > 0 &&
          this.getQuestionnaire.feedback_point > 0 &&
          (this.getQuestionnaire.positive_filter_setting !== '' ||
            this.getQuestionnaire.exclude_filter_setting !== '');
        if (!isSetInfo) {
          this.$alert(
            '您有必填項目未填寫，請檢查後填寫完成並按「儲存」',
            '問卷資訊必填項目未填寫',
            {
              type: 'error',
              confirmButtonText: '確定',
            }
          )
            .then(() => {
              this.toggleActiveTab('1');
            })
            .catch(() => {
              this.toggleActiveTab('1');
            });
          return false;
        }
      }
      //檢查有沒有設定題目
      if (this.check.checkQuestion) {
        const isSetQuestion = this.getTempQuestionQueue.length > 0;
        if (!isSetQuestion) {
          this.$alert('尚未設定題目', 'ERROR!', {
            type: 'error',
            confirmButtonText: '確定',
          })
            .then(() => {
              this.toggleActiveTab('2');
            })
            .catch(() => {
              this.toggleActiveTab('2');
            });
          return false;
        }
      }
      //檢查群組內有沒有設定題目
      if (this.check.checkGroupQuestion) {
        const isSetGroupQuestion = this.getTempQuestionQueue
          .filter((e) => e.question_type === 2)
          .map((e) => e.question_array.length)
          .every((e) => e > 0);
        if (!isSetGroupQuestion) {
          this.$alert('題組尚未設定題目', 'ERROR!', {
            type: 'error',
            confirmButtonText: '確定',
          })
            .then(() => {
              this.toggleActiveTab('2');
            })
            .catch(() => {
              this.toggleActiveTab('2');
            });
          return false;
        }
      }
      //檢查有沒有儲存排序
      if (this.check.checkSaveSort) {
        if (this.getChangeOrder) {
          this.$alert('尚未儲存目前的題目排序', '注意!', {
            type: 'warning',
            confirmButtonText: '確定',
          })
            .then(() => {
              this.toggleActiveTab('2');
            })
            .catch(() => {
              this.toggleActiveTab('2');
            });
          return false;
        }
      }
      return true;
    },
  },
};

export default validateQuestion;
