<template>
  <el-dialog
    v-if="showGroupDialog"
    :title="action === 'create' ? '新增 - 題組' : '編輯 - 題組'"
    :visible="showGroupDialog"
    @close="handleClose"
    width="60%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="groupInfo"
      ref="ruleForm"
      label-position="left"
      label-width="70px"
      :rules="rules"
      :status-icon="true"
      :hide-required-asterisk="true"
      @submit.native.prevent
    >
      <el-form-item label="名稱" prop="name">
        <el-input
          v-model="groupInfo.name"
          placeholder="請輸入群組名稱，字數限制120字以內"
        ></el-input>
      </el-form-item>
      <el-form-item label="背景色" prop="bg_color">
        <el-radio-group v-model="groupInfo.bg_color" style="flex-wrap: wrap">
          <el-radio
            v-for="item in group_color"
            :key="item.label"
            :label="item.label"
            border
            class="bg_radio"
            :style="{
              'background-color': item.label,
            }"
          >
            {{ item.text }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="說明" prop="description">
        <el-input
          v-model="groupInfo.description"
          type="textarea"
          :rows="5"
          placeholder="請輸入群組說明，字數限制200字以內"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">確定送出</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'EditCroup',
  props: ['showGroupDialog', 'groupInfo', 'action'],
  data() {
    return {
      group_color: [
        { text: '灰底', label: '#F7F7F7' },
        { text: '藍底', label: '#EBF8FF' },
        { text: '深灰底', label: '#E2E2E2' },
      ],
      rules: {
        name: [
          { required: true, message: '請輸入題組名稱', trigger: 'blur' },
          { max: 120, message: '字數不可超過120字', trigger: 'change' },
        ],
        description: [
          { max: 200, message: '字數不可超過200字', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    handleClose() {
      this.$refs['ruleForm'].resetFields();
      this.$emit('update:showGroupDialog', false);
    },
    async handleSubmit() {
      const valid = await this.$refs['ruleForm'].validate().catch((e) => e);
      if (!valid) return false;
      this.$emit('submitGroup', this.groupInfo);
      this.$emit('update:showGroupDialog', false);
    },
  },
};
</script>
<style scoped>
.bg_radio {
  margin: 0 0 5px;
}
</style>
